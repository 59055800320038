import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';

// const _key = (index) => {
//     return `key-${index}-${Math.floor(Math.random() * 99999) + 1}`;
// }

var options = { year: "numeric", month: "long", day: "numeric" };

function AttributeValue(props) {
    const { loading, attributeList, handleValueChange, checkedArray } = props;
    // if (loading) {
    //     return <Box component="section" sx={{ p: 2, border: '1px dashed grey' }}>
    //         Please wait...
    //     </Box>
    // }

    if (attributeList.length === 0) {
        return <Box component="section" sx={{ p: 2, border: '1px dashed grey' }}>
            No varient selected
        </Box>
    }
    return <>
        {
            attributeList.map((attribute, index) => {
                const { id, name, attribute_values } = attribute;
                return (
                    <Box component="section" sx={{ p: 2, border: '1px dashed grey' }}>
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            component="nav"
                            aria-hidden="false"
                        >
                            <ListItemButton className="list-atter">
                                <ListItemText primary={name} />
                            </ListItemButton>
                            <Collapse in="true" timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>

                                    {attribute_values.map((attributeValue, index) => {
                                        let valueId = attributeValue.id;
                                        let valueIdStr = valueId.toString();
                                        const value = attributeValue.value;
                                        let checkedItem=false;
                                        if(checkedArray.includes(valueIdStr)){
                                            checkedItem=true;
                                        }
                                        return (
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        defaultChecked={checkedItem}
                                                        name={`${id}-${valueId}`}
                                                        chekced={checkedItem}
                                                        onChange={handleValueChange}
                                                        value={`${id}-${valueId}`}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={value} />
                                            </ListItemButton>
                                        )
                                    })}
                                </List>
                            </Collapse>
                        </List>
                    </Box>
                )
            })
        }
    </>
}

export default AttributeValue;