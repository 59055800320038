import { image } from "src/constants/api";
import { Stack, TableRow, TableCell, Paper, CircularProgress, Typography, IconButton, Avatar } from "@mui/material";

import { SwitchComponent } from "../../../components/Switch";
import Iconify from "../../../components/iconify";
import { Link } from "react-router-dom";
import { DEFAULT_URL } from "src/app/config/endpoints";

const _key = (index) => {
    return `key-${index}-${Math.floor(Math.random() * 99999) + 1}`;
}

var options = { year: "numeric", month: "long", day: "numeric" };

function ProductItem(props) {
    const { loading, handlePublished, handleOpenMenu, errorMessage, productList, searchTextDebounce, isDesktop, navWidth, srNo } = props;
    if (loading) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <CircularProgress />
                <Typography variant="subtitle2" className="user-name" noWrap>
                    Please wait...
                </Typography>
            </TableCell>
        </TableRow>
    }

    if (errorMessage) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <Typography
                    sx={{ color: 'red' }}
                    variant="subtitle2" className="user-name" noWrap>
                    {errorMessage}
                </Typography>
            </TableCell>
        </TableRow>
    }

    if (productList.length === 0) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <Paper sx={{ textAlign: "center", }}>
                    <Typography variant="h6" paragraph>
                        Data Not found
                    </Typography>
                </Paper>
            </TableCell>
        </TableRow>
    }
    return <>
        {
            productList.map((product, index) => {
                const { id, product_image, category, name, quantity, price, special_price, status, featured, sku_code } = product;
                return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="attribute word-wrap">
                                    <Avatar
                                        src={
                                            product_image?.image
                                                ? `${DEFAULT_URL.IMAGE}/product/${product_image.image}`
                                                : "./assets/images/web-logo.png"
                                        }
                                        style={{ height: "80px", width: "80px" }}
                                        alt=""
                                    />
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="attribute word-wrap">
                                    {sku_code}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="attribute word-wrap">
                                    {category.name}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="attribute word-wrap">
                                    {name}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="attribute word-wrap">
                                    <b>Quantity:</b> {quantity} <br />
                                    <b>Price:</b> {price} <b>Special Price:</b> <del>{special_price}</del>
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell align="left">
                            <SwitchComponent
                                data={status}
                                handlePublished={handlePublished}
                                column="status"
                                id={id}
                                isOnClickTrigger={true}
                            />
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <SwitchComponent
                                data={featured}
                                handlePublished={handlePublished}
                                column="featured"
                                id={id}
                                isOnClickTrigger={true}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <IconButton size="small" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )
            })
        }
    </>
}

export default ProductItem;