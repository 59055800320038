import { Helmet } from "react-helmet-async";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import $ from "jquery";
import { createNotification } from "../../common/createNotification";
import { SeoFriendlySlug, checkTokenExpiry, matchYoutubeUrl } from "../../utils/helpers/functions";


// @mui
import {
  Card,
  CardHeader,
  Stack,
  Button,
  MenuItem,
  Grid,
  Container,
  Typography,
  CircularProgress,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Icon } from "@iconify/react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNewsBulletin } from "src/redux/Slices/NewsSlice";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  getNewsBulletin,
  getNewsBulletinSubCategory,
} from "src/api/newsBulletinApi";
import { getNewsById, getUsers, updateNews } from "src/api/newsList";
import { image, port, video } from "src/constants/api";
import { editPodcast, getPodcastById } from "src/api/podcast";
import { useTheme } from "@emotion/react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function EditNews() {
  //STATES----------------------------------------------------

  const [newsInput, setnewsInput] = useState({
    newsTitle: "",
    newsSlug: '',
    summary: "",
    image_caption:"",
    description: "",
    main_Category: "",
    sub_Category: [],
    user: "",
    bulletin: [],
    date: new Date(),
    published: false,
    image_high: "",
    video: "",
    link: "",
    video_link: "",
  });

  const [news, setnews] = useState({});
  const [bulletin, setnewsBulletin] = useState([]);
  const { newsBulletin } = useSelector((state) => state.news);
  const [podcast, setpodcast] = useState(false);
  const [mediaErrors, setmediaErrors] = useState("");
  const [loading, setloading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, seterror] = useState("");
  const [mainCategory, setMainCategory] = useState();
  const [subCategoryData, setSubCategoryData] = useState();
  const [selectMainCategory, setSelectMainCategory] = useState();
  const [mainCategoryDataList, setMainCategoryDataList] = useState([]);
  const [subCategoryDataList, setSubCategoryDataList] = useState([]);
  const [responsedata, setResponseData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [users, setAllusers] = useState([]);
  const [selectedUser, setSelecteduser] = useState();

  //CONSTANTS---------------------------------------------------
  const formTop = useRef();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const pathName = window.location.pathname.split("/")[2];
  const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);
  const theme = useTheme();

  const SUPPORTED_FORMATS_VIDEOS = [
    "video/mp4",
    "video/webm",
    "video/ogg",
    "video/mpeg",
    "video/mkv",
  ];
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/webp",
    "image/avif",
  ];

  const handleRouteTo = (path) => {
    navigate(path);
  };

  // validation and react hook form
  const validationSchema = Yup.object().shape({
    newsTitle: Yup.string().trim().required("Title is required"),
    newsSlug: Yup.string().trim().required("Slug is required"),
    summary: Yup.string().trim().required("Summary is required"),
    image_caption:Yup.string().trim(),
    description: Yup.string().trim().required("Description is required"),
    main_Category: Yup.string().required("News bulletin is required"),
    sub_Category: Yup.mixed(),
    user: Yup.string().required("User is required"),
    // .required("Sub News bulletin is required"),
    // img: Yup.mixed()
    //   .test("file", "You need to provide a file", (value) => {
    //     if (value.length > 0 || !mediaErrors?.image) {
    //       return true;
    //     }
    //     setnewsInput({ ...newsInput, image_high: "" });
    //     return false;
    //   }),
    newsImage: Yup.mixed()
      .test("required", "This field is required.", (file) => {
        if (file || file === undefined) return true;
        return false;
      })
      .test("fileSize", "Image size can not be more than 5 MB", (file) => {
        console.log("==> ", file);
        if (file === undefined || file.length === 0)
          return true;

        return file.length && file[0].size <= 5242880;
      })
      .test("fileType", "Only *.jpeg, *.jpg, *.png type of images are allowed.", (file) => {
        if (file === undefined || file.length === 0)
          return true;

        return (
          file.length &&
          ["image/jpg", "image/jpeg", "image/gif", "image/png", "image/webp", "image/avif",].includes(file[0].type)
        );
      }),
    // link:
    //   newsInput.link &&
    //   Yup.string().test("fileType", "Not correct URL", (value) => {
    //     return get_youtube_thumbnail(value, "high");
    //   }),
  });

  const validationSchemaPodcast = Yup.object().shape({
    summary: Yup.string().trim().required("Summary is required"),
    newsTitle: Yup.string().trim().required("Title is required"),
    newsSlug: Yup.string().trim().required("Slug is required"),
    description: Yup.string().trim().required("Description is required"),
    // img: Yup.mixed().test("file", "You need to provide a file", (value) => {
    //   if (value.length > 0 || !mediaErrors?.image) {
    //     return true;
    //   }
    //   setnewsInput({ ...newsInput, image_high: "" });
    //   return false;
    // }),
    newsImage: Yup.mixed()
      .test("required", "This field is required.", (file) => {
        if (file || file === undefined) return true;
        return false;
      })
      .test("fileSize", "Image size can not be more than 5 MB", (file) => {
        console.log("==> ", file);
        if (file === undefined || file.length === 0)
          return true;

        return file.length && file[0].size <= 5242880;
      })
      .test("fileType", "Only *.jpeg, *.jpg, *.png type of images are allowed.", (file) => {
        if (file === undefined || file.length === 0)
          return true;

        return (
          file.length &&
          ["image/jpg", "image/jpeg", "image/gif", "image/png", "image/webp", "image/avif",].includes(file[0].type)
        );
      }),
    link: Yup.string().required("Podcast link is required."),
    // link:
    //   newsInput.link &&
    //   Yup.string().test("fileType", "Not correct URL", (value) => {
    //     return get_youtube_thumbnail(value, "high");
    //   }),
  });

  const formOptions = {
    resolver: yupResolver(
      pathName === "news-podcast" ? validationSchemaPodcast : validationSchema
    ),
  };

  const { register, handleSubmit, formState, setValue, getValues, trigger, reset } =
    useForm(formOptions);

  const { errors } = formState;
  const [selectedImage, setSelectedImage] = useState(null);
  const { onChange, ...params } = register("newsImage");

  //USE EFFECTS--------------------------------------------

  useEffect(() => {
    if (pathName === "news-podcast") {
      setpodcast(true);
    }
  }, [pathName]);

  useEffect(() => {
    register("ckeditorInput");
    if (errors.description?.message) {
      $(".ck-content").removeClass("ck-focused");
      $(".ck-content").addClass("errorCkEditor");
    }
  });

  useEffect(() => {
    getBulletin();
    getSelectedNews();
    // getSubCategory();
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBulletin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCategory]);

  useEffect(() => {
    reset(newsInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsInput]);

  //FUNCTIONS----------------------------------------------

  //validation for youtube video link

  function get_youtube_thumbnail(url, quality) {
    if (url) {
      var video_id, thumbnail, result;
      if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
        video_id = result.pop();
      } else if ((result = url.match(/youtu.be\/(.{11})/))) {
        video_id = result.pop();
      }
      if (video_id) {
        if (typeof quality == "undefined") {
          quality = "high";
        }
        var quality_key = "maxresdefault"; // Max quality
        // eslint-disable-next-line eqeqeq
        if (quality == "low") {
          quality_key = "sddefault";
          // eslint-disable-next-line eqeqeq
        } else if (quality == "medium") {
          quality_key = "mqdefault";
          // eslint-disable-next-line eqeqeq
        } else if (quality == "high") {
          quality_key = "hqdefault";
        }
        thumbnail =
          "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
        return thumbnail;
      }
    }
    return false;
  }
  const getAllUsers = async () => {
    try {
      const data = await getUsers();

      setAllusers(data.data);
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
    }
  };

  ///get news from redux
  const getBulletin = async () => {
    try {
      const data = await getNewsBulletin();
      dispatch(setNewsBulletin(data.data));
      const filteredBulletin = data.data.filter((e) => e.status == 1);
      setMainCategoryDataList(filteredBulletin);
      if (mainCategory) {
        const filteredBulletinSubCategory = data.data.filter(
          (e) => e.id != mainCategory
        );
        setSubCategoryDataList(filteredBulletinSubCategory);
        setSubCategoryData(filteredBulletinSubCategory);
      } else {
        const filteredBulletinSubCategory = data.data.filter(
          (e) => e.id != responsedata
        );
        setSubCategoryDataList(filteredBulletinSubCategory);
        setSubCategoryData(filteredBulletinSubCategory);
      }
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
    }
  };

  const getSubCategory = async () => {
    try {
      const data = await getNewsBulletinSubCategory();
      const filteredBulletin = data.data.filter((e) => e.status == 1);
      // dispatch(setNewsBulletin(filteredBulletin));
      setSubCategoryData(data.data);
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
    }
  };

  const filteredBulletin = newsBulletin.filter(
    // eslint-disable-next-line eqeqeq
    (newsBullet) => newsBullet.status == 1
  );

  const getSelectedNews = async () => {
    setloading(true);
    if (pathName === "news-podcast") {
      try {
        const res = await getPodcastById(id);
        let podcastLink;
        if (res?.data.podcast_yt_video_link) {
          podcastLink = res?.data?.podcast_yt_video_link
        } else {
          podcastLink = res?.data?.podcast_video_link
        }
        setnewsInput({
          ...newsInput,
          newsTitle: res?.data.title,
          newsSlug: res?.data.post_name,
          image_high: `${res?.data.image_high ? `${image}/${res?.data.image_high}` : ''}`,
          recent: res?.data.recent,
          date: new Date(res?.data.published_date),
          published: !!+res?.data.publish_status,
          summary: res?.data.summary,
          video_link: res?.data.podcast_video_link,
          video: `${video}/${res?.data.upload_video}`,
          description: res.data.description,
          link: podcastLink,
          user: res.data.created_by_user_id,

        });
        setnews(res.data);
        setloading(false);
      } catch (error) {
        // checkTokenExpiry(error.response.data.msg);
        setloading(false);
      }
    } else {
      try {
        const res = await getNewsById(id);
        setResponseData(res.data.news_bulletin?.id);
        setnewsInput({
          ...newsInput,
          main_Category: res.data.news_bulletin.id,
          sub_Category: res?.data.sub_categories.map((e) => e.id),
          newsTitle: res?.data.title,
          newsSlug: res?.data.post_name,
          summary: res?.data.summary,
          image_caption: res?.data.image_caption,
          user: res.data.created_by_user_id,
          date: new Date(res?.data.published_date),
          published: !!+res?.data.publish_status,
          recent: !!+res?.data.recent,
          image_high: `${res?.data.image_high ? `${image}/${res?.data.image_high}` : ''}`,
          // video: `${video}/${res?.data.news_video_link}`,
          video: `${res?.data.news_video_link ? `${video}/${res?.data.news_video_link}` : ''}`,
          description: res.data.description,
          link: res?.data.news_yt_video_link,
        });
        setnews(res.data);
        setloading(false);
      } catch (error) {
        // checkTokenExpiry(error.response.data.msg);
        setloading(false);
      }
    }
  };
  //handle changes

  const handleChange = (event) => {
    seterror("");
    setmediaErrors("");
    setnewsInput({
      ...newsInput,
      [event.target.name]: event.target.name === 'newsSlug' ? SeoFriendlySlug(event.target.value) : event.target.value,
    });
  };


  const handleUserChange = (e) => {
    setSelecteduser(e.target.value);
  };

  const handleImage = (event) => {
    const description = getValues('description');
    const image = event.target.files[0];
    if (!image.name.match(/\.(jpg|jpeg|png|gif|webp|avif)$/)) {
      setmediaErrors({ ...mediaErrors, image: "File format not supported" });
      setnewsInput({ ...newsInput, image_high: "" });
      return false;
    }
    setnewsInput({
      ...newsInput,
      image_high: URL.createObjectURL(event.target.files[0]),
      imgFile: image,
      description: description,
    });
  };

  const handleFile = (e) => {
    const image = e.target.files[0];
    if (!image)
      return false;

    // register("picture");
    // if (!image.name.match(/\.(jpg|jpeg|png|gif|webp|avif)$/)) {
    //   setitemData("");
    //   return false;
    // }

    setnewsInput({
      ...newsInput,
      image_high: URL.createObjectURL(e.target.files[0]),
      imgFile: image,
    });
  };

  const handleVideo = (event) => {
    const video = event.target.files[0];

    // if (!video.name.match(/\.(mp4)$/)) {
    //   setmediaErrors({ ...mediaErrors, video: "File format not supported" });
    //   setnewsInput({
    //     ...newsInput,
    //     [event.target.name]: URL.createObjectURL(event.target.files[0]),
    //     videoFile: null,
    //   });
    //   return false;
    // }
    setmediaErrors("");
    setnewsInput({
      ...newsInput,
      [event.target.name]: URL.createObjectURL(event.target.files[0]),
      videoFile: video,
    });
  };

  //submit form

  const onSubmitForm = async (data) => {

    // return false;
    if (mainCategory) {
      const subCategoryFinal = newsInput.sub_Category.filter(
        (e) => e != mainCategory
      );
      const formData = new FormData();
      formData.append("files", newsInput?.imgFile);

      formData.append("files", newsInput?.videoFile);

      !podcast && formData.append("news_bulletin_names", subCategoryFinal);

      formData.append("title", newsInput.newsTitle);
      formData.append("post_name", newsInput.newsSlug);

      formData.append("summary", newsInput.summary);

      formData.append("description", newsInput.description);

      // podcast && formData.append("podcast_yt_video_link", data.link);

      // pathName === "news-podcast"
      //   ? formData.append("video_link", newsInput.video_link)
      //   : formData.append("news_yt_video_link", newsInput.link);

      //   formData.append(
      //     podcast 
      //       ? matchYoutubeUrl(data.link) 
      //         ? "podcast_yt_video_link"
      //         : "podcast_video_link"
      //       : "news_yt_video_link",
      //   data.link
      // );

      if (matchYoutubeUrl(data.link)) {
        formData.append("podcast_yt_video_link", data.link);
        formData.append("podcast_video_link", null);
      } else {
        formData.append("podcast_yt_video_link", null);
        formData.append("podcast_video_link", data.link);
      }

      formData.append("published_date", newsInput.date);
      formData.append("main_category", newsInput.main_Category);

      formData.append("publish_status", +newsInput?.published);

      formData.append("recent", 0);

      formData.append("updated_by_user_id", newsInput?.user);

      formData.append("id", podcast ? id : news.id);
      seterror("");
      setloading(true);
      if (pathName !== "news-podcast") {
        try {
          await updateNews(formData);
          handleRouteTo("/dashboard/news");
          createNotification(
            "success",
            "Updated",
            `News was updated successfully`
          );
        } catch (error) {
          checkTokenExpiry(error.response.data.msg);
          setloading(false);
          createNotification(
            "error",
            "Error",
            `Error updating news, try again!`
          );
        }
      } else {
        try {
          await editPodcast(formData);
          handleRouteTo("/dashboard/news-podcast");
          createNotification(
            "success",
            "Updated",
            `Podcast was updated successfully`
          );
        } catch (error) {
          checkTokenExpiry(error.response.data.msg);
          setloading(false);
          createNotification(
            "error",
            "Error",
            `Error updating podcast, try again!`
          );
        }
      }
    } else {
      const subCategoryFinal = newsInput.sub_Category.filter(
        (e) => e != responsedata
      );
      const formData = new FormData();
      formData.append("files", newsInput?.imgFile);

      formData.append("files", newsInput?.videoFile);

      !podcast && formData.append("news_bulletin_names", subCategoryFinal);

      formData.append("title", newsInput.newsTitle);
      formData.append("post_name", newsInput.newsSlug);

      formData.append("summary", newsInput.summary);

      formData.append("description", data.description);

      if (matchYoutubeUrl(data.link)) {
        formData.append("podcast_yt_video_link", data.link);
        formData.append("podcast_video_link", null);
      } else {
        formData.append("podcast_yt_video_link", null);
        formData.append("podcast_video_link", data.link);
      }
      // podcast && formData.append("podcast_yt_video_link", data.link);

      // pathName === "news-podcast"
      //   ? formData.append("video_link", newsInput.video_link)
      //   : formData.append("news_yt_video_link", newsInput.link);

      formData.append("published_date", newsInput.date);
      formData.append("main_category", newsInput.main_Category);

      formData.append("publish_status", +newsInput?.published);

      formData.append("recent", 0);

      formData.append("updated_by_user_id", newsInput?.user);

      formData.append("id", podcast ? id : news.id);

      seterror("");

      setloading(true);

      if (pathName !== "news-podcast") {
        try {
          formData.append("image_caption", newsInput.image_caption);
          await updateNews(formData);

          handleRouteTo("/dashboard/news");
          createNotification(
            "success",
            "Updated",
            `News was updated successfully`
          );
          setloading(false);
        } catch (error) {
          checkTokenExpiry(error.response.data.msg);
          setloading(false);
          createNotification(
            "error",
            "Error",
            `Error updating news, try again!`
          );
        }
      } else {
        try {
          await editPodcast(formData);
          handleRouteTo("/dashboard/news-podcast");
          createNotification(
            "success",
            "Updated",
            `Podcast was updated successfully.`
          );
        } catch (error) {
          checkTokenExpiry(error.response.data.msg);
          setloading(false);
          createNotification(
            "error",
            "Error",
            `Error updating podcast, try again!`
          );
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title> {podcast ? "Podcast" : "News"} </title>
      </Helmet>
      {loading && (
        <Box
          onClick={() => setloading(false)}
          sx={{
            display: "flex",
            position: "fixed",
            height: "100%",
            width: "75%",
            bottom: "0",
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",

            zIndex: "1000",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Container ref={formTop}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={0}
        >
          <Typography variant="h4" gutterBottom>
            {podcast ? "Podcast" : "News"}
          </Typography>
        </Stack>
        <Breadcrumbs aria-label="breadcrumb" mb={5}>
          <Link style={{ textDecoration: "none", color: "black" }} to="/">
            Dashboard
          </Link>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`/dashboard/${pathName}`}
          >
            {path}
          </Link>
          <Typography color="text.primary">Edit {path}</Typography>
        </Breadcrumbs>
        <Card>
          <CardHeader title={`Edit ${path}`} />
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitForm)}
            sx={{ pt: 5, pl: 3, pr: 3, pb: 5 }}
          >
            <Grid container spacing={4} alignItems="start">
              <Grid item xs={12} md={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  {...register("newsTitle", {
                    required: true,
                    onChange: handleChange,
                  })}
                  error={errors?.newsTitle}
                  helperText={
                    errors.newsTitle ? errors.newsTitle.message : false
                  }
                  id="news-title"
                  label="Title*"
                  name="newsTitle"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  {...register("newsSlug", {
                    required: true,
                    onChange: handleChange,
                  })}
                  error={errors?.newsSlug}
                  helperText={
                    errors.newsSlug ? errors.newsSlug.message : false
                  }
                  id="news-title"
                  label="slug*"
                  name="newsSlug"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  {...register("summary", {
                    required: true,
                    onChange: handleChange,
                  })}
                  InputLabelProps={{ shrink: true }}

                  error={errors?.summary}
                  helperText={errors.summary ? errors.summary.message : false}
                  id="news-summary"
                  label="Summary*"
                  name="summary"
                  variant="outlined"
                  rows={3}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <div className={errors?.description ? "errorCkEditor" : ""}>
                  <CKEditor
                    name="description"
                    style={{
                      position: "relative",
                      zIndex: "20",
                    }}
                    config={{
                      placeholder: "Description*",
                      ckfinder: {
                        uploadUrl: `${port}/upload`,
                      },
                    }}
                    editor={ClassicEditor}
                    data={newsInput?.description || ""}
                    onChange={(event, editor) => {
                      setValue("description", editor.getData());
                      trigger("description");
                    }}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "100%",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onBlur={(event, editor) => { }}
                    onFocus={(event, editor) => {
                      if (errors?.description?.message) {
                        $(".ck-content").removeClass("ck-focused");
                        $(".ck-content").addClass("errorCkEditor");
                      }
                    }}
                  />
                </div>
                {errors.description ? (
                  <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained bulletin-error">
                    {errors.description.message}
                  </p>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} md={podcast ? 12 : 6}>
                <div
                  className={errors.picture ? "errorCkEditor" : "borderInput"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "20px",
                    postition: "relative",
                    height: newsInput.image_high || newsInput.video ? '275px' : 'auto'

                  }}
                >
                  {newsInput.image_high !== null && newsInput.image_high !== '' ? (
                    <>
                      {!podcast ? (
                        <div className="uploaded-media newsimag">
                          <img
                            src={newsInput.image_high}
                            alt="newsInput"
                            loading="lazy"
                          />
                        </div>
                      ) : (
                        <div className="uploaded-media podcasetimg">
                          <img
                            src={newsInput.image_high}
                            alt="something"
                            loading="lazy"
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <Icon
                        fontSize="60px"
                        icon="material-symbols:image-rounded"
                      />
                      <span
                        style={{
                          color: errors.newsImage ? "#ff5c56" : "#8d9299",
                          fontSize: "14px",
                          marginBottom: "20px",
                          textAlign: 'center'
                        }}
                      >
                        Only *.jpeg, *.jpg, *.png type of images are allowed, Image size can not be more than 5 MB, Recomended resolution 1170 × 500.
                      </span>
                    </>
                  )}

                  <Button variant="contained" component="label">
                    Upload image
                    <input
                      {...params}
                      type="file"
                      id="uploadFileInput"
                      accept="image/*"
                      name="newsImage"
                      onChange={(event) => {
                        setSelectedImage(event.target.files[0]);
                        onChange(event);
                        trigger('newsImage');
                        handleFile(event)
                      }}
                      style={{ display: 'none' }}
                    />
                  </Button>
                </div>
                {errors.newsImage ? (
                  <p className="errorForm">{errors.newsImage.message}</p>
                ) : (
                  ""
                )}
              </Grid>

              {!podcast && (
                <>
                  <Grid item xs={12} md={podcast ? 12 : 6}>
                    <div
                      className={
                        (pathName === "news-podcast" && errors.picture) ||
                          mediaErrors.video
                          ? "errorCkEditor"
                          : "borderInput"
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "20px",
                        postition: "relative",
                        height: newsInput.image_high || newsInput.video ? '275px' : 'auto',
                        minHeight: '200px'
                      }}
                    >
                      {newsInput.video ? (
                        <div style={{ position: "relative" }}>
                          <div className="uploaded-media">
                            <video
                              controls
                              autoPlay
                              src={newsInput.video}
                              alt="data"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <Icon
                            fontSize="60px"
                            icon="material-symbols:video-call-rounded"
                          />
                          <span
                            style={{
                              color: "#8d9299",
                              fontSize: "14px",
                              marginBottom: "20px",
                            }}
                          >
                            Only mp4, webm, ogg and mpeg files are supported
                          </span>
                        </>
                      )}

                      <Button variant="contained" component="label">
                        Upload Video
                        <input
                          type="file"
                          name="video"
                          accept="video/mp4, video/webm, video/ogg, video/mpeg,video/mkv"
                          onChange={handleVideo}
                          hidden
                        />
                      </Button>
                    </div>
                    {mediaErrors.video && (
                      <span style={{ fontSize: "12px", color: "#D61E27" }}>
                        {mediaErrors.video}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      {...register("image_caption", {
                        required: true,
                        onChange: handleChange,
                      })}
                      InputLabelProps={{ shrink: true }}

                      error={errors?.image_caption}
                      helperText={errors.image_caption ? errors.image_caption.message : false}
                      id="image_caption"
                      label="Image Caption*"
                      name="image_caption"
                      variant="outlined"
                      rows={3}
                      multiline
                      fullWidth
                    />
                  </Grid>
                </>
              )}

              {
                podcast &&

                <Grid item xs={12} md={6}>
                  <TextField
                    {...register("link", {
                      required: true,
                      onChange: (data) => {
                        // setmediaErrors({ ...mediaErrors, yt_link: "" });
                        setnewsInput({
                          ...newsInput,
                          link: data.target.value,
                        });
                      },
                    })}
                    error={errors.link}
                    helperText={errors.link?.message}
                    id="link"
                    label="Podcast Link*"
                    name="link"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                  />
                  {mediaErrors.yt_link && (
                    <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained bulletin-error">
                      {mediaErrors.yt_link}"
                    </p>
                  )}
                </Grid>
              }

              {!podcast && (
                <Grid item xs={12} md={6}>
                  <FormControl
                    error={errors.bulletin?.message}
                    name="bulletin"
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel className={`${errors.main_Category?.message && 'bulletin-error'}`} id="demo-simple-select-standard-label">
                      News Bulletin*
                    </InputLabel>
                    <Select
                      labelId="news-bulletin-label"
                      id="news-bulletin"
                      name="bulletin"
                      value={newsInput.main_Category}
                      {...register("main_Category", {
                        required: true,
                        onChange: (e) => {
                          setMainCategory(e.target.value);
                          setnewsInput({
                            ...newsInput,
                            main_Category: e.target.value,
                          });
                        },
                      })}
                      label="News Bulletin*"
                      // input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                    >
                      {mainCategoryDataList.map((name) => (
                        <MenuItem
                          key={name.name}
                          value={name.id}
                          style={getStyles(name.id, bulletin, theme)}
                        >
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors.main_Category?.message
                        ? errors.main_Category?.message
                        : false}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}

              {!podcast && (
                <Grid item xs={12} md={6}>
                  <FormControl
                    name="bulletin"
                    fullWidth
                    variant="outlined"
                    error={errors.bulletin?.message}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Sub News Bulletin
                    </InputLabel>
                    <Select
                      labelId="news-bulletin-label"
                      id="news-bulletin"
                      name="bulletin"
                      value={newsInput.sub_Category}
                      {...register("sub_Category", {
                        onChange: (e) => {
                          setnewsInput({
                            ...newsInput,
                            sub_Category: e.target.value,
                          });
                        },
                      })}
                      input={<OutlinedInput label="Sub News Bulletin" />}
                      multiple
                      MenuProps={MenuProps}
                    >
                      {subCategoryDataList.map((name) => (
                        <MenuItem
                          key={name.name}
                          value={name.id}
                          style={getStyles(name.id, bulletin, theme)}
                        >
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors.sub_bulletin?.message
                        ? errors.sub_bulletin?.message
                        : false}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} md={6}>
                <Stack spacing={3} direction="column">
                  <FormControl
                    error={errors.user}
                    name="selectedUser"
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Users*
                    </InputLabel>
                    <Select
                      labelId="selectedUser-label"
                      id="news-bulletin"
                      name="user"
                      value={newsInput.user}
                      {...register("user", {
                        required: true,
                        onChange: (e) => {
                          setSelecteduser(e.target.value);
                          setnewsInput({
                            ...newsInput,
                            user: e.target.value,
                          });
                        },
                      })}
                      label="Users*"
                      // input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                    >
                      {users.map((name) => (
                        <MenuItem key={name.name} value={name.id}>
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors.user?.message ? errors.user?.message : false}
                    </FormHelperText>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormControl style={{ width: "100%" }}>
                    <DesktopDatePicker
                      label="Date"
                      value={
                        newsInput.date ? newsInput.date : new Date().getTime()
                      }
                      inputFormat="DD/MM/YYYY"
                      name="date"
                      onChange={(newValue) =>
                        setnewsInput({ ...newsInput, date: newValue })
                      }
                      renderInput={(params) => (
                        <TextField width={100} variant="outlined" {...params} />
                      )}
                    />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <Stack spacing={3} direction="row">
                    <FormControlLabel
                      control={
                        <Switch
                          name="published"
                          checked={newsInput?.published}
                          onChange={(event) =>
                            setnewsInput({
                              ...newsInput,
                              published: event.target.checked,
                            })
                          }
                          color="secondary"
                        />
                      }
                      label="Published"
                    />
                    {/* {
                      !podcast &&
                      <FormControlLabel
                        control={
                          <Switch
                            name="recent"
                            checked={newsInput?.recent || false}
                            onChange={(event) =>
                              setnewsInput({
                                ...newsInput,
                                recent: event.target.checked,
                              })
                            }
                            color="secondary"
                          />
                        }
                        label="Recent News"
                      />
                    } */}
                  </Stack>
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={12}>
                {error && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {error}
                  </span>
                )}
                <Button type="submit" variant="contained">
                  Update
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Container>
    </>
  );
}

export default EditNews;
