import { Helmet } from "react-helmet-async";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import { checkTokenExpiry } from "../../utils/helpers/functions";

import { LoadingButton } from '@mui/lab';
import { useEffect } from "react";

// @mui
import { Card, CardHeader, Stack, Grid, Container, Typography, CircularProgress, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Chip } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { createNotification } from "../../common/createNotification";
import { setRequestInProgress, setRequestFailure, setRequestInit } from "../../redux/Slices/RequestSlice";

import { postRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS } from 'src/app/config/endpoints';
import {
    getAttributes
} from "src/api/e-commerce/product";
import AttributeValue from "./attributevalue";

function AddProductParent() {
    //STATES
    const [loading, setloading] = useState(false);
    const { loader } = useSelector(state => state.serviceRequest);
    const [attributeList, setAttributeList] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState([]);
    const [selectedHtmlData, setSelectedHtmlData] = useState([]);
    const [selecteValueData, setSelecteValueData] = useState({});
    const [variationSelected, setVariationSelected] = useState([]);
    const [optionChecked, setOptionChecked] = useState([]);
    //CONSTANTS
    const formTop = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pathName = window.location.pathname.split("/")[2];
    const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);
    const validationSchema = Yup.object().shape({
        sku: Yup.string().required("This field is required."),
        variation: Yup.string().required("This field is required."),
    });

    const formOptions = {
        resolver: yupResolver(validationSchema),
    };

    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const handleRouteTo = (path) => {
        navigate(path);
    };

    const onSubmitForm = async (data) => {
        dispatch(setRequestInProgress());
        const payloadData = {
            sku: data.sku,
            variation: data.variation,
            variants: selecteValueData
        }
        //console.log(payloadData);
        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.ADD_PRODUCT_PARENT, payloadData);
            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Product SKU created successfully.");
                handleRouteTo("/e-commerce/product/add_product/" + data.product.id);
            } else {
                dispatch(setRequestFailure("Getting error while creating Attribute."));
                createNotification("error", "", "Getting error while creating Attribute.");
            }
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            dispatch(setRequestFailure("Getting error while creating Attribute."));
            createNotification("error", "", "Getting error while creating Attribute.");
        }
    };

    const getAttribute = async () => {
        try {
            const data = await getAttributes();
            if (data.data.data !== null) {
                let dataAccess = data.data.data;
                let NewData = [];
                dataAccess.forEach(function (data) {
                    NewData[data.id] = data.name;
                });
                //console.log(NewData);
                setAttributeList(NewData);
            }
        } catch (error) {
            checkTokenExpiry(error);
        }
    };

    const getAttributeValues = async (aAttributeId) => {
        setSelectedHtmlData([]);
        try {
            let payload = { ids: aAttributeId };
            const data = await postRequest(GENERAL_SETTINGS.GET_ATTRIBUTE_WITH_VALUES, payload);
            
            setSelectedHtmlData(data.data.data);
            //console.log(data.data.data);

        } catch (error) {
            checkTokenExpiry(error);
        }
    }

    const handleChange = (e) => {
        //console.log(e.target.value)
        let difference = selectedAttribute.filter(number => !e.target.value.includes(number));
        if (difference.length > 0) {
            let indexToRemove = difference[0].toString();
            const keyexists = Object.keys(selecteValueData).includes(indexToRemove);
            if (keyexists) {
                delete selecteValueData[indexToRemove];
                setSelecteValueData(selecteValueData);
            }
        }
        setSelectedAttribute(e.target.value);
        getAttributeValues(e.target.value);
    }

    const handleValueChange = (e) => {
        //console.log(e.target.value)
        const old = selecteValueData;
        const oldOne = optionChecked;
        const arraySplit = e.target.value.split("-");
        if (e.target.checked) {
            oldOne.push(arraySplit[1])
            const keyexist = Object.keys(old).filter((val) => val === arraySplit[0]);
            if (keyexist.length > 0) {
                if (arraySplit[1]) {
                    const index = old[arraySplit[0]].indexOf(arraySplit[1]);
                    if (index === -1) {
                        old[arraySplit[0]].push(arraySplit[1])
                    }
                }
            } else {
                old[arraySplit[0]] = [arraySplit[1]];
            }
        } else {
            const index1 = oldOne.indexOf(arraySplit[1]);
            if (index1 > -1) { // only splice array when item is found
                oldOne.splice(index1, 1); // 2nd parameter means remove one item only
            }


            const index = old[arraySplit[0]].indexOf(arraySplit[1]);
            if (index > -1) { // only splice array when item is found
                old[arraySplit[0]].splice(index, 1); // 2nd parameter means remove one item only
            }
            if (old[arraySplit[0]].length == 0) {
                delete old[arraySplit[0]];
            }
        }
        //console.log(oldOne);
        setOptionChecked(oldOne);
        setSelecteValueData(old);
    }

    const handleChangeVariation = (e) => {
        setVariationSelected(e.target.value);
        if (e.target.value === 'simple') {
            setSelecteValueData({});
            setSelectedAttribute([]);
            setSelectedHtmlData([]);
        }
    };

    useEffect(() => {
        getAttribute();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <>
            <Helmet>
                <title> Add Product </title>
            </Helmet>

            {loading && (
                <Box
                    onClick={() => setloading(false)}
                    sx={{
                        display: "flex",
                        position: "fixed",
                        height: "100%",
                        width: "75%",
                        bottom: "0",
                        backgroundColor: "white",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: "1000",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            <Container ref={formTop}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={0}
                >
                    <Typography variant="h4" gutterBottom>
                        Add Product
                    </Typography>
                </Stack>
                <Breadcrumbs aria-label="breadcrumb" mb={5}>
                    <Link style={{ textDecoration: "none", color: "black" }} to="/">
                        Dashboard
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/e-commerce/product/listing`}
                    >
                        Product
                    </Link>
                    <Typography color="text.primary">Create Product</Typography>
                </Breadcrumbs>

                <Card>
                    <CardHeader title={`Create Product`} />
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmitForm)}
                        sx={{ pt: 5, pl: 3, pr: 3, pb: 5 }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >

                            <Grid item md={12}>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("sku", { required: true })}
                                        error={errors.sku}
                                        helperText={
                                            errors.sku ? errors.sku.message : false
                                        }
                                        required="true"
                                        disabled={loader}
                                        id="news-title"
                                        label="Product SKU Code*"
                                        name="sku"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <FormControl fullWidth>
                                        <Stack>
                                            <InputLabel id="demo-simple-select-standard-label">
                                                Variation
                                            </InputLabel>
                                            <Select
                                                id="variation"
                                                name="variation"
                                                defaultValue=""
                                                error={errors?.parent_id}
                                                value={variationSelected}
                                                {...register("variation", {
                                                    onChange: handleChangeVariation,
                                                })}
                                                input={<OutlinedInput label="Variation" />}
                                                label="Variation"
                                                required="true"
                                            >
                                                <MenuItem key="simple" value="simple">
                                                    Simple
                                                </MenuItem>
                                                <MenuItem key="variation" value="variation">
                                                    Variation
                                                </MenuItem>
                                            </Select>
                                        </Stack>
                                    </FormControl>
                                </Grid>


                                {variationSelected && variationSelected === 'variation' ?
                                    <>
                                        <Grid item mb={3} xs={12} md={12}>
                                            <FormControl fullWidth>
                                                <Stack>
                                                    <InputLabel id="demo-simple-select-standard-label">
                                                        Attributes
                                                    </InputLabel>
                                                    <Select
                                                        multiple
                                                        id="parent_id"
                                                        name="parent_id"
                                                        error={errors?.parent_id}
                                                        input={<OutlinedInput label="Variation" />}
                                                        label="Variation"
                                                        value={selectedAttribute}
                                                        onChange={handleChange}
                                                        renderValue={(selected) => (
                                                            <Stack gap={1} direction="row" flexWrap="wrap">
                                                                {selected.map((value) => (
                                                                    <Chip key={value} label={attributeList[value]} />
                                                                ))}
                                                            </Stack>
                                                        )}
                                                    >
                                                        {attributeList.map((value, key) => (
                                                            <MenuItem key={key} value={key}>
                                                                {value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Stack>
                                            </FormControl>
                                        </Grid>

                                        <Grid item mb={3} xs={12} md={12}>

                                            <AttributeValue
                                                loading={loader}
                                                attributeList={selectedHtmlData}
                                                checkedArray={optionChecked}
                                                handleValueChange={handleValueChange}
                                            />

                                        </Grid>
                                    </> : ''
                                }

                                <Grid
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    textAlign="center"
                                    my={2}
                                >
                                    <LoadingButton
                                        loading={loader ? true : false}
                                        size="medium"
                                        type="submit"
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        {loader ? 'PLEASE WAIT...' : 'SUBMIT'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Container>
        </>
    );
}

export default AddProductParent;