import { Helmet } from "react-helmet-async";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import { checkTokenExpiry } from "../../utils/helpers/functions";

import { LoadingButton } from '@mui/lab';
import { useEffect } from "react";

// @mui
import { Card, CardHeader, Stack, Grid, Container, Typography, Fab, CircularProgress, Checkbox, InputLabel, Select, FormControl, MenuItem, OutlinedInput } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import UploadIcon from '@mui/icons-material/Upload';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { createNotification } from "../../common/createNotification";
import { setRequestInProgress, setRequestFailure, setRequestInit } from "../../redux/Slices/RequestSlice";

import { postRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS } from 'src/app/config/endpoints';
import {
    getProductParentApi
} from "src/api/e-commerce/product";

function AddProduct() {
    //STATES
    const [loading, setloading] = useState(false);
    const { loader } = useSelector(state => state.serviceRequest);
    const [productParent, setProductParent] = useState([]);
    const [dynamicFormValues, setDynamicFormValues] = useState({});
    const [mainCategory, setMainCategory] = useState();
    //CONSTANTS
    const { id } = useParams();
    const formTop = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //const formId = Math.floor(Date.now() / 1000);
    const pathName = window.location.pathname.split("/")[2];
    const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required."),
        category_id: Yup.number().required("This field is required."),
        meta_title: Yup.string().required("This field is required."),
        meta_key: Yup.string().required("This field is required."),
        meta_description: Yup.string().required("This field is required."),
        description: Yup.string().required("This field is required."),
        short_description: Yup.string().required("This field is required."),
    });

    const formOptions = {
        resolver: yupResolver(validationSchema),
    };

    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const handleRouteTo = (path) => {
        // console.log(path)
        navigate(path);
    };

    const onSubmitForm = async (data) => {
        //console.log(dynamicFormValues);
        dispatch(setRequestInProgress());
        const formData = new FormData();
        const payloadData = {
            name: data.name,
            category_id: data.category_id,
            form_id: productParent.form_id,
            parent_id: id,
            meta_title: data.meta_title,
            meta_key: data.meta_key,
            meta_description: data.meta_description,
            description: data.description,
            short_description: data.short_description,
            product_data: dynamicFormValues
        }
        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.ADD_PRODUCT, payloadData);
            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Product created successfully.");
                handleRouteTo("/e-commerce/product/listing");
            } else {
                dispatch(setRequestFailure("Getting error while creating Attribute."));
                createNotification("error", "", "Getting error while creating Attribute.");
            }
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            dispatch(setRequestFailure("Getting error while creating Attribute."));
            createNotification("error", "", "Getting error while creating Attribute.");
        }
    };

    const getProductParent = async () => {
        try {
            const { data } = await getProductParentApi(id);
            if (data !== null) {
                let dataParent = data;
                //console.log(dataParent);
                setProductParent(dataParent);
            }
        } catch (error) {
            //console.log(error, 'here comes');
            if (error.response.data.err === 'Invalid link...') {
                handleRouteTo("/e-commerce/product/listing");
            }
            checkTokenExpiry(error);
        }
    };

    const handleChange = (e) => {
        if (e.target.title !== 'product[image]' || e.target.title !== 'product[images]') {
            setDynamicFormValues({ ...dynamicFormValues, [e.target.name]: e.target.value });
        }
    };

    const handleFileChange = (param) => async (e) => {
        dispatch(setRequestInProgress());
        const formData = new FormData();
        formData.append("form_id", productParent.form_id);
        formData.append("attribute_ids", param);
        if (e.target.name === "product_image") {
            formData.append("main_image", 1);
        } else {
            formData.append("main_image", 2);
        }
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append("product_images", e.target.files[i]);
        }
        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.PRODUCT_IMAGE_UPLOAD, formData);
            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Images uploaded successfully.");
            } else {
                dispatch(setRequestFailure("Getting error while creating Product Image"));
                createNotification("error", "", "Getting error while creating Product Image");
            }
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            dispatch(setRequestFailure("Getting error while creating Product Image"));
            createNotification("error", "", "Getting error while creating Product Image.");
        }
    };

    const handleChangeCategory = (e) => {
        //console.log(e.target.value);
        setMainCategory(e.target.value);
    };

    useEffect(() => {
        getProductParent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <>
            <Helmet>
                <title> Add Product Detail </title>
            </Helmet>

            {loading && (
                <Box
                    onClick={() => setloading(false)}
                    sx={{
                        display: "flex",
                        position: "fixed",
                        height: "100%",
                        width: "75%",
                        bottom: "0",
                        backgroundColor: "white",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: "1000",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            <Container ref={formTop}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={0}
                >
                    <Typography variant="h4" gutterBottom>
                        Product Detail
                    </Typography>
                </Stack>
                <Breadcrumbs aria-label="breadcrumb" mb={5}>
                    <Link style={{ textDecoration: "none", color: "black" }} to="/">
                        Dashboard
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/e-commerce/product/listing`}
                    >
                        Product
                    </Link>
                    <Typography color="text.primary">Create Product Detail</Typography>
                </Breadcrumbs>

                <Card>
                    <CardHeader title={`Create Product Detail`} />
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmitForm)}
                        sx={{ pt: 5, pl: 3, pr: 3, pb: 5 }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >

                            <Grid item md={12}>
                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("name", { required: true })}
                                        error={errors.name}
                                        helperText={
                                            errors.name ? errors.name.message : false
                                        }
                                        disabled={loader}
                                        id="name"
                                        label="Product Name*"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item mb={3} xs={12} md={12}>

                                    <FormControl fullWidth>
                                        <Stack>
                                            <InputLabel id="demo-simple-select-standard-label">
                                                Select Category
                                            </InputLabel>
                                            <Select
                                                id="category_id"
                                                label="Select Category"
                                                error={errors?.category_id}
                                                value={mainCategory}
                                                {...register("category_id", {
                                                    onChange: handleChangeCategory,
                                                })}
                                                input={<OutlinedInput label="Select Category" />}
                                            >
                                                {productParent?.categories && productParent.categories.map((element) => (
                                                    <MenuItem key={element.name} value={element.id}>
                                                        {element.name}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                        </Stack>
                                    </FormControl>
                                </Grid>

                                <TableContainer mb={3}>
                                    <Table className="Product_Table" sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead className="table_head_news">
                                            <TableRow>
                                                <TableCell sx={{
                                                    color: "#fff",
                                                    background: "#000",
                                                    py: 2,
                                                }}>Variation</TableCell>
                                                <TableCell sx={{
                                                    color: "#fff",
                                                    background: "#000",
                                                    py: 2,
                                                }} align="left">#S.no	</TableCell>
                                                <TableCell sx={{
                                                    color: "#fff",
                                                    background: "#000",
                                                    py: 2,
                                                }} align="left">Title</TableCell>
                                                <TableCell sx={{
                                                    color: "#fff",
                                                    background: "#000",
                                                }} align="left">Qty</TableCell>
                                                <TableCell sx={{
                                                    color: "#fff",
                                                    background: "#000",
                                                    py: 2,
                                                }} align="left">MRP</TableCell>
                                                <TableCell sx={{
                                                    color: "#fff",
                                                    background: "#000",
                                                    py: 2,
                                                }} align="left">Price</TableCell>
                                                <TableCell sx={{
                                                    color: "#fff",
                                                    background: "#000",
                                                    py: 2,
                                                }} align="left">Image</TableCell>
                                                <TableCell sx={{
                                                    color: "#fff",
                                                    background: "#000",
                                                    py: 2,
                                                }} align="left">Other Image</TableCell>
                                                <TableCell sx={{
                                                    color: "#fff",
                                                    background: "#000",
                                                    py: 2,
                                                }} align="left">Status</TableCell>
                                                <TableCell sx={{
                                                    color: "#fff",
                                                    background: "#000",
                                                    py: 2,
                                                }} align="left">Featured</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {productParent?.variation && productParent.variation.map((variantData, index, array) => {
                                                let title = '';
                                                let attributeids = Object.values(variantData).join(',');
                                                for (const element1 in variantData) {
                                                    title = title + productParent.variantTitle[variantData[element1]] + '-';
                                                }
                                                title = title.slice(0, -1);
                                                return (
                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index + 1}>
                                                        <TableCell component="th" scope="row">{title}</TableCell>
                                                        <TableCell align="left">{`${productParent.productParent.sku}-${index + 1}`}</TableCell>
                                                        <TableCell align="left">
                                                            <TextField id="outlined-basic" label="Title" variant="outlined" size="small" name={`product_name_${index}`} onChange={handleChange} />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField type="number" id="outlined-basic" label="Qty" variant="outlined" size="small" name={`product_quantity_${index}`} onChange={handleChange} />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField type="number" id="outlined-basic" label="MRP" variant="outlined" size="small" name={`product_price_${index}`} onChange={handleChange} />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField id="outlined-basic" label="Selling Price" variant="outlined" size="small" name={`product_special_price_${index}`} onChange={handleChange} />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <label htmlFor={`upload-photo-${index}`}>
                                                                <input
                                                                    style={{ display: "none" }}
                                                                    id={`upload-photo-${index}`}
                                                                    type="file"
                                                                    name={`product_image`} onChange={handleFileChange(attributeids)}
                                                                    title="product_image"
                                                                />
                                                                <Fab color="primary" size="small" component="span" aria-label="add">
                                                                    <UploadIcon />
                                                                </Fab>
                                                            </label>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <label htmlFor={`upload-photo-multiple-${index}`}>
                                                                <input
                                                                    style={{ display: "none" }}
                                                                    id={`upload-photo-multiple-${index}`}
                                                                    type="file"
                                                                    multiple={true}
                                                                    name={`product_images`}
                                                                    title="product_images"
                                                                    onChange={handleFileChange(attributeids)}
                                                                />
                                                                <Fab color="primary" size="small" component="span" aria-label="add">
                                                                    <UploadIcon />
                                                                </Fab>
                                                            </label>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Checkbox name={`product_status_${index}`} onChange={handleChange}></Checkbox>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Checkbox name={`product_featured_${index}`} onChange={handleChange}></Checkbox>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}

                                            {productParent?.productParent && productParent.productParent.type === 0 && 
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={1} >
                                                    <TableCell component="th" scope="row">--</TableCell>
                                                    <TableCell align="left">{`${productParent.productParent.sku}-${0 + 1}`}</TableCell>
                                                    <TableCell align="left">
                                                        <TextField id="outlined-basic" label="Title" variant="outlined" size="small" name={`product_name`} onChange={handleChange} />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <TextField type="number" id="outlined-basic" label="Qty" variant="outlined" size="small" name={`product_quantity`} onChange={handleChange} />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <TextField type="number" id="outlined-basic" label="MRP" variant="outlined" size="small" name={`product_price`} onChange={handleChange} />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <TextField id="outlined-basic" label="Selling Price" variant="outlined" size="small" name={`product_special_price`} onChange={handleChange} />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <label htmlFor={`upload-photo`}>
                                                            <input
                                                                style={{ display: "none" }}
                                                                id={`upload-photo`}
                                                                type="file"
                                                                name={`product_image`} onChange={handleFileChange(0)}
                                                                title="product_image"
                                                            />
                                                            <Fab color="primary" size="small" component="span" aria-label="add">
                                                                <UploadIcon />
                                                            </Fab>
                                                        </label>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <label htmlFor={`upload-photo-multiple`}>
                                                            <input
                                                                style={{ display: "none" }}
                                                                id={`upload-photo-multiple`}
                                                                type="file"
                                                                multiple={true}
                                                                name={`product_images`}
                                                                title="product_images"
                                                                onChange={handleFileChange(0)}
                                                            />
                                                            <Fab color="primary" size="small" component="span" aria-label="add">
                                                                <UploadIcon />
                                                            </Fab>
                                                        </label>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Checkbox name={`product_status`} onChange={handleChange}></Checkbox>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Checkbox name={`product_featured`} onChange={handleChange}></Checkbox>
                                                    </TableCell>
                                                </TableRow>
                                                
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("short_description", { required: true })}
                                        disabled={loader}
                                        multiline
                                        rows={5}
                                        error={errors.short_description}
                                        helperText={
                                            errors.short_description ? errors.short_description.message : false
                                        }
                                        id="short_description"
                                        label="Short Description*"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("description", { required: true })}
                                        multiline
                                        rows={10}
                                        disabled={loader}
                                        error={errors.description}
                                        helperText={
                                            errors.description ? errors.description.message : false
                                        }
                                        id="description"
                                        label="Description*"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("meta_title", { required: true })}
                                        disabled={loader}
                                        error={errors.meta_title}
                                        helperText={
                                            errors.meta_title ? errors.meta_title.message : false
                                        }
                                        id="meta-title"
                                        label="Meta Title*"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("meta_key", { required: true })}
                                        disabled={loader}
                                        error={errors.meta_key}
                                        helperText={
                                            errors.meta_key ? errors.meta_key.message : false
                                        }
                                        id="meta-key"
                                        label="Meta Key*"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("meta_description", { required: true })}
                                        disabled={loader}
                                        error={errors.meta_description}
                                        helperText={
                                            errors.meta_description ? errors.meta_description.message : false
                                        }
                                        id="meta-description"
                                        label="Meta Description*"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>


                                <Grid
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    textAlign="center"
                                    my={2}
                                >
                                    <LoadingButton
                                        loading={loader ? true : false}
                                        size="medium"
                                        type="submit"
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        {loader ? 'PLEASE WAIT...' : 'SUBMIT'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Container>
        </>
    );
}

export default AddProduct;