import { Container, Typography } from "@mui/material";

function PageContainer( props ){
    const {heading, children} = props;
    return <>
        <Container sx={{padding:'5px 10px 0 !important'}} {...props}>
            { heading 
                ? <Typography variant="h4" sx={{ mb: 0.5 }}>
                {heading}
            </Typography>:""}
            {children}
        </Container>
    </>
}
export default PageContainer;