export const BASEURL = process.env.REACT_APP_HOST_URL;
export const PREFIX = '/admin';
export const SAMPLE_API = `${BASEURL}${PREFIX}/sample-api`;

export const GENERAL_SETTINGS_KEYS={
    DEFAULT_STATUS:1,
    STATUS_ACTIVE:1,
    STATUS_INACTIVE:0,
    SOCIAL_LINKS:'SOCIAL_LINKS',
    HEADER_LOGO:'HEADER_LOGO',
    FOOTER_BANNER:'FOOTER_BANNER',
    HEADER_TEXT_AND_TIME:'HEADER_TEXT_AND_TIME',
    HEADER_CONTENT:'HEADER_CONTENT',
    FOOTER_CONTENT:'FOOTER_CONTENT',
    FOOTER_LOGO_DESC:'FOOTER_LOGO_DESC',
    FOOTER_CONTACT_INFO:'FOOTER_CONTACT_INFO',
    ABOUT_US:'ABOUT_US',
    PRIVACY_POLICY:'PRIVACY_POLICY',
    RESOURCES:'RESOURCES',
}

export const GENERAL_SETTINGS = {
    HEADER:`${BASEURL}${PREFIX}/getHeader?HEADER=HEADER`,
    GET_HEADERS:`${BASEURL}${PREFIX}/getHeaders`,
    // GET_SITE_DATA:`${BASEURL}${PREFIX}/getSiteData?HEADER=HEADER`,
    GET_SITE_DATA:`${BASEURL}${PREFIX}/getSiteData`,
    GET_SITE_FEATURE:`${BASEURL}${PREFIX}/getSiteFeatures`,
    SET_GENERAL_SETTINGS:`${BASEURL}${PREFIX}/setGeneralSettings`,
    UPLOAD_IMAGE:`${BASEURL}${PREFIX}/uploadImage`,
    GET_SPONSERS:`${BASEURL}${PREFIX}/getSponsorsList`,
    GET_SPONSERS_BY_ID:`${BASEURL}${PREFIX}/getSponsorData?id={ID}`,
    ADD_SPONSERS:`${BASEURL}${PREFIX}/addSponsor`,
    UPDATE_SPONSERS_STATUS:`${BASEURL}${PREFIX}/changeStatusSponsor`,
    UPDATE_SPONSERS:`${BASEURL}${PREFIX}/editSponsor`,
    DELETE_SPONSERS:`${BASEURL}${PREFIX}/deleteSponsor/{ID}`,

    GET_PAGE_FEATURE:`${BASEURL}${PREFIX}/getPageFeature`,
    SET_PAGE_FEATURE:`${BASEURL}${PREFIX}/setStaticPages`,

    GET_CATEGORY:`${BASEURL}${PREFIX}/getCategoryList`,
    GET_CATEGORY_BY_ID:`${BASEURL}${PREFIX}/getCategoryData?id={ID}`,
    ADD_CATEGORY:`${BASEURL}${PREFIX}/addCategory`,
    UPDATE_CATEGORY_STATUS:`${BASEURL}${PREFIX}/changeStatusCategory`,
    UPDATE_CATEGORY_FEATURED:`${BASEURL}${PREFIX}/changeFeaturedCategory`,
    UPDATE_CATEGORY:`${BASEURL}${PREFIX}/editCategory`,
    DELETE_CATEGORY:`${BASEURL}${PREFIX}/deleteCategory/{ID}`,

    GET_ATTRIBUTE:`${BASEURL}${PREFIX}/getAttributeList`,
    GET_ATTRIBUTE_BY_ID:`${BASEURL}${PREFIX}/getAttributeData?id={ID}`,
    ADD_ATTRIBUTE:`${BASEURL}${PREFIX}/addAttribute`,
    UPDATE_ATTRIBUTE_STATUS:`${BASEURL}${PREFIX}/changeStatusAttribute`,
    UPDATE_ATTRIBUTE:`${BASEURL}${PREFIX}/editAttribute`,
    DELETE_ATTRIBUTE:`${BASEURL}${PREFIX}/deleteAttribute/{ID}`,
    GET_ATTRIBUTE_WITH_VALUES:`${BASEURL}${PREFIX}/getAttributesWithValues`,


    GET_ATTRIBUTE_VALUE:`${BASEURL}${PREFIX}/getAttributeValueList`,
    GET_ATTRIBUTE_VALUE_BY_ID:`${BASEURL}${PREFIX}/getAttributeValueData?id={ID}`,
    ADD_ATTRIBUTE_VALUE:`${BASEURL}${PREFIX}/addAttributeValue`,
    UPDATE_ATTRIBUTE_VALUE_STATUS:`${BASEURL}${PREFIX}/changeStatusAttributeValue`,
    UPDATE_ATTRIBUTE_VALUE:`${BASEURL}${PREFIX}/editAttributeValue`,
    DELETE_ATTRIBUTE_VALUE:`${BASEURL}${PREFIX}/deleteAttributeValue/{ID}`,

    GET_PRODUCTS:`${BASEURL}${PREFIX}/getProductList`,
    ADD_PRODUCT_PARENT:`${BASEURL}${PREFIX}/addProductParent`,
    ADD_PRODUCT:`${BASEURL}${PREFIX}/addProduct`,
    PRODUCT_IMAGE_UPLOAD:`${BASEURL}${PREFIX}/product_image_upload`,
    UPDATE_PRODUCT_STATUS:`${BASEURL}${PREFIX}/changeProductStatus`,
    DELETE_PRODUCT:`${BASEURL}${PREFIX}/deleteProduct/{ID}`,
    GET_PRODUCT_BY_ID:`${BASEURL}${PREFIX}/getProductById?id={ID}`,
    DELETE_PRODUCT_IMAGE:`${BASEURL}${PREFIX}/deleteProductImage/{ID}`,
    PRODUCT_IMAGE_UPDATE:`${BASEURL}${PREFIX}/product_image_update`,
    UPDATE_PRODUCT:`${BASEURL}${PREFIX}/updateProduct`,

    GET_AUTHOR:`${BASEURL}${PREFIX}/getAuthorList`,
    GET_AUTHOR_BY_ID:`${BASEURL}${PREFIX}/getAuthorData?id={ID}`,
    ADD_AUTHOR:`${BASEURL}${PREFIX}/addAuthor`,
    UPDATE_AUTHOR_STATUS:`${BASEURL}${PREFIX}/changeStatusAuthor`,
    UPDATE_AUTHOR:`${BASEURL}${PREFIX}/editAuthor`,
    DELETE_AUTHOR:`${BASEURL}${PREFIX}/deleteAuthor/{ID}`,

    UPDATE_NEWS_BULLETIN_MENU:`${BASEURL}${PREFIX}/changeShowMenuStatusBulletin`,


    GET_ORDERS_LIST:`${BASEURL}${PREFIX}/getOrdersList`,
    GET_ORDERS_DETAILS:`${BASEURL}${PREFIX}/getOrderDetail/`,

}


export const DEFAULT_URL={
    IMAGE:`${process.env.REACT_APP_IMAGE_URL}`,
    VIDEO:`${process.env.REACT_APP_VIDOE_URL}`,
}
export const ASSETS_URL={
    IMAGE:`${process.env.REACT_APP_ASSETS_URL}`,
}
