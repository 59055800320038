import "./Product.css"
import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import { checkTokenExpiry } from "../../utils/helpers/functions";
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
// @mui
import { Card, CardHeader, Stack, Avatar, Grid, Container, Typography, CircularProgress, OutlinedInput, MenuItem, FormControl, InputLabel, Fab } from "@mui/material";
import UploadIcon from '@mui/icons-material/Upload';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createNotification } from "../../common/createNotification";
import { Icon } from "@iconify/react";
import { setRequestInProgress, setRequestSuccess, setRequestFailure, setRequestInit } from "../../redux/Slices/RequestSlice";
import { getRequest, postRequest, deleteRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS, GENERAL_SETTINGS_KEYS, ASSETS_URL } from 'src/app/config/endpoints';
import {
    getMainCategory
} from "src/api/e-commerce/category";
import { DEFAULT_URL } from "src/app/config/endpoints";
import DeleteIcon from '@mui/icons-material/Delete';
import AlertDialog from "../../common/AlertDialog";


function EditProduct() {

    const { id } = useParams();

    const [loading, setloading] = useState(false);
    const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);
    const [productData, setProductData] = useState([]);
    const [mainCategory, setMainCategory] = useState();
    const [mainCategoryDataList, setMainCategoryDataList] = useState([]);
    const [categoryId, setCategoryId] = useState(0);
    const [productDetail, setProductDetail] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [open, setOpen] = useState(null);
    const [hoveredImageId, setHoveredImageId] = useState(null);


    //CONSTANTS
    const formTop = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pathName = window.location.pathname.split("/")[2];
    const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required."),
        category_id: Yup.number().required("This field is required."),
        short_description: Yup.string().required("This field is required."),
        description: Yup.string().required("This field is required."),
        price: Yup.number().required("This field is required."),
        special_price: Yup.number().required("This field is required."),
        meta_title: Yup.string(),
        meta_keys: Yup.string(),
        meta_description: Yup.string(),
    });

    const formOptions = {
        resolver: yupResolver(validationSchema),
    };

    const { register, unregister, handleSubmit, formState, trigger, setValue, getValues } = useForm(formOptions);
    const { errors } = formState;

    const handleRouteTo = (path) => {
        navigate(path);
    };

    const handleClickOpen = (imageId) => {
        setHoveredImageId(imageId)
        setModalShow(true);
    };

    const deleteProductImageById = async () => {
        try {
            setOpen(null);
            setloading(true);
            const { status, data: { data } } = await deleteRequest(GENERAL_SETTINGS.DELETE_PRODUCT_IMAGE.replace('{ID}', hoveredImageId));
            setloading(false);
            createNotification("success", "Success", `Product image was successfully deleted.`);
            getProduct(id);
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            createNotification("error", "Update", "Getting error while deleting product image. Please try  again.");
        }
    };

    const onSubmitForm = async (data) => {
        console.log(data);
        dispatch(setRequestInProgress());
        const formData = new FormData();
        formData.append("id", id);
        formData.append("name", data.name);
        formData.append("category_id", data.category_id);
        formData.append("short_description", data.short_description);
        formData.append("description", data.description);
        formData.append("price", data.price);
        formData.append("special_price", data.special_price);
        formData.append("quantity", data.quantity);
        formData.append("meta_title", data.meta_title);
        formData.append("meta_keys", data.meta_keys);
        formData.append("meta_description", data.meta_description);
        formData.append("status", data.status ? 1 : 0);
        formData.append("featured", data.featured ? 1 : 0);

        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.UPDATE_PRODUCT, formData);
            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Product updated successfully.");
                handleRouteTo("/e-commerce/product/listing");
            } else {
                dispatch(setRequestFailure("Getting error while updating Product."));
                createNotification("error", "", "Getting error while updating Product.");
            }
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            dispatch(setRequestFailure("Getting error while creating Product."));
            createNotification("error", "", "Getting error while creating Product.");
        }
    };

    async function getProduct(id) {
        try {
            dispatch(setRequestInProgress());
            const response = await getRequest(GENERAL_SETTINGS.GET_PRODUCT_BY_ID.replace('{ID}', id));
            const { status, data: { data } } = response;
            if (status === 200 && data) {
                setProductDetail(data);
                setCategoryId(data.category_id);
                setValue('category_id', data.category_id);
                setValue('name', data.name);
                setValue('short_description', data.short_description);
                setValue('description', data.description);
                setValue('price', data.price);
                setValue('special_price', data.special_price);
                setValue('quantity', data.quantity);
                setValue('meta_title', data.meta_title);
                setValue('meta_keys', data.meta_keys);
                setValue('meta_description', data.meta_description);
                setValue('status', data.status);
                setValue('featured', data.featured);
                dispatch(setRequestInit());
            } else if (data === null) {
                dispatch(setRequestFailure("Product not found."));
                createNotification("error", "", "Product not found.");
                handleRouteTo('/e-commerce/product/listing')
            }
        } catch (error) {
            dispatch(setRequestFailure("Getting error while removing profile picture. Please try  again."));
        }
    }

    const getMainSelectCategory = async () => {
        try {
            const data = await getMainCategory(id);
            if (data.data.data !== null) {
                setMainCategoryDataList(data.data.data);
            }
        } catch (error) {
            checkTokenExpiry(error);
        }
    };

    const handleChangeCategory = (e) => {
        setMainCategory(e.target.value);
    };

    const handleChange = (event) => {
        setProductDetail({ ...productDetail, status: event.target.checked });
    };

    const handleChangeFeatured = (event) => {
        setProductDetail({ ...productDetail, featured: event.target.checked });
    };

    const handleFileChange = (param) => async (e) => {
        dispatch(setRequestInProgress());
        const formData = new FormData();
        formData.append("product_id", id);
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append("product_images", e.target.files[i]);
        }
        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.PRODUCT_IMAGE_UPDATE, formData);
            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Images uploaded successfully.");
                getProduct(id);
            } else {
                dispatch(setRequestFailure("Getting error while creating Product Image"));
                createNotification("error", "", "Getting error while creating Product Image");
            }
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            dispatch(setRequestFailure("Getting error while creating Product Image"));
            createNotification("error", "", "Getting error while creating Product Image.");
        }
    };

    useEffect(() => {
        getProduct(id);
        getMainSelectCategory();
    }, [id]);
    return (
        <>
            <Helmet>
                <title> Update Product </title>
            </Helmet>

            {loading && (
                <Box
                    onClick={() => setloading(false)}
                    sx={{
                        display: "flex",
                        position: "fixed",
                        height: "100%",
                        width: "75%",
                        bottom: "0",
                        backgroundColor: "white",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: "1000",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            <Container ref={formTop}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={0}
                >
                    <Typography variant="h4" gutterBottom>
                        Update Product
                    </Typography>
                </Stack>
                <Breadcrumbs aria-label="breadcrumb" mb={5}>
                    <Link style={{ textDecoration: "none", color: "black" }} to="/">
                        Dashboard
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/e-commerce/product/listing`}
                    >
                        Product
                    </Link>
                    <Typography color="text.primary">Update Product</Typography>
                </Breadcrumbs>

                <Card>
                    <CardHeader title={`Update Product`} />
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmitForm)}
                        sx={{ pt: 5, pl: 3, pr: 3, pb: 5 }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item md={12}>
                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        {...register("name", { required: true })}
                                        error={errors.name}
                                        helperText={
                                            errors.name ? errors.name.message : false
                                        }
                                        disabled={loader}
                                        id="name"
                                        label="Product Name*"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <FormControl fullWidth>
                                        <Stack>
                                            <InputLabel id="demo-simple-select-standard-label">
                                                Select Category
                                            </InputLabel>
                                            <Select
                                                id="category_id"
                                                label="Select Category"
                                                error={errors?.category_id}
                                                value={categoryId}
                                                {...register("category_id", {
                                                    onChange: handleChangeCategory,
                                                })}
                                                input={<OutlinedInput label="Select Category" />}
                                            >
                                                {mainCategoryDataList && mainCategoryDataList.map((element) => (
                                                    <MenuItem key={element.name} value={element.id}>
                                                        {element.name}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                        </Stack>
                                    </FormControl>
                                </Grid>

                                <Grid display="flex" mb={3} spacing={5}>
                                    {productDetail.product_images && productDetail.product_images.map((value) => (
                                        <Grid item className="list-select-images">
                                            <span className="trash-img" onClick={() => { handleClickOpen(value.id) }}> <DeleteIcon /></span>
                                            <Avatar
                                                src={`${DEFAULT_URL.IMAGE}/product/${value.image}`}
                                                style={{ height: "100px", width: "100px" }}
                                                alt="" variant="square"
                                            />
                                        </Grid>
                                    ))}

                                    <label htmlFor={`upload-photo-multiple-1`}>
                                        <input
                                            style={{ display: "none" }}
                                            id={`upload-photo-multiple-1`}
                                            type="file"
                                            multiple={true}
                                            name={`product_images`}
                                            title="product_images"
                                            onChange={handleFileChange()}
                                        />
                                        <Fab color="primary" size="small" component="span" aria-label="add">
                                            <UploadIcon />
                                        </Fab>
                                    </label>
                                </Grid>


                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("short_description", { required: true })}
                                        InputLabelProps={{ shrink: true }}
                                        disabled={loader}
                                        multiline
                                        rows={5}
                                        error={errors.short_description}
                                        helperText={
                                            errors.short_description ? errors.short_description.message : false
                                        }
                                        id="short_description"
                                        label="Short Description*"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("description", { required: true })}
                                        InputLabelProps={{ shrink: true }}
                                        multiline
                                        rows={10}
                                        disabled={loader}
                                        error={errors.description}
                                        helperText={
                                            errors.description ? errors.description.message : false
                                        }
                                        id="description"
                                        label="Description*"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("price", { required: true })}
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.price}
                                        helperText={
                                            errors.price ? errors.price.message : false
                                        }
                                        disabled={loader}
                                        id="price"
                                        label="Price*"
                                        type='number'
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("special_price", { required: true })}
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.special_price}
                                        helperText={
                                            errors.special_price ? errors.special_price.message : false
                                        }
                                        disabled={loader}
                                        id="special_price"
                                        label="Special Price*"
                                        type='number'
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("quantity", { required: true })}
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.quantity}
                                        helperText={
                                            errors.quantity ? errors.quantity.message : false
                                        }
                                        disabled={loader}
                                        id="quantity"
                                        label="Quantity*"
                                        type='number'
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("meta_title", { required: true })}
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.meta_title}
                                        helperText={
                                            errors.meta_title ? errors.meta_title.message : false
                                        }
                                        disabled={loader}
                                        id="meta_title"
                                        label="Meta Title*"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("meta_keys", { required: true })}
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.meta_keys}
                                        helperText={
                                            errors.meta_keys ? errors.meta_keys.message : false
                                        }
                                        disabled={loader}
                                        id="meta_keys"
                                        label="Meta Keys*"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("meta_description", { required: true })}
                                        InputLabelProps={{ shrink: true }}
                                        error={errors.meta_description}
                                        helperText={
                                            errors.meta_description ? errors.meta_description.message : false
                                        }
                                        disabled={loader}
                                        id="meta_description"
                                        label="Meta Description*"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <FormGroup>
                                        <Stack spacing={3} direction="row">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        {...register("status")}
                                                        checked={productDetail.status}
                                                        name="status"
                                                        onChange={handleChange}
                                                        disabled={loader}
                                                        color="success"
                                                    />
                                                }
                                                label="Status"
                                            />
                                        </Stack>
                                    </FormGroup>
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <FormGroup>
                                        <Stack spacing={3} direction="row">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        {...register("featured")}
                                                        checked={productDetail.featured}
                                                        name="featured"
                                                        onChange={handleChangeFeatured}
                                                        disabled={loader}
                                                        color="success"
                                                    />
                                                }
                                                label="Featured"
                                            />
                                        </Stack>
                                    </FormGroup>
                                </Grid>


                                <Grid
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    my={2}
                                >
                                    <LoadingButton
                                        loading={loader ? true : false}
                                        size="medium"
                                        type="submit"
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        {loader ? 'PLEASE WAIT...' : 'UPDATE'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    <AlertDialog
                        open={modalShow}
                        setOpen={setModalShow}
                        deletefunction={deleteProductImageById}
                        moduleType={'product image'}
                    />
                </Card>
            </Container>
        </>
    );
}

export default EditProduct;