import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useParams } from "react-router-dom";

import { checkTokenExpiry } from "../../utils/helpers/functions";

import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';

// @mui
import { Card, CardHeader, Stack, Button, Grid, Container, Typography, CircularProgress, OutlinedInput, MenuItem, FormControl, InputLabel } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { createNotification } from "../../common/createNotification";
import { Icon } from "@iconify/react";

import { setRequestInProgress, setRequestSuccess, setRequestFailure, setRequestInit } from "../../redux/Slices/RequestSlice";

import { getRequest, postRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS, GENERAL_SETTINGS_KEYS, ASSETS_URL } from 'src/app/config/endpoints';
import {
    getMainCategory
} from "src/api/e-commerce/category";


function EditCategory() {

    const { id } = useParams();

    const [loading, setloading] = useState(false);
    const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);
    const [categoryDetail, setCategoryDetail] = useState({});
    const [categoryImgPreview, setCategoryImgPreview] = useState();
    const [mainCategory, setMainCategory] = useState();
    const [mainCategoryDataList, setMainCategoryDataList] = useState([]);
    const [parentId, setParentId] = useState(0);

    //CONSTANTS
    const formTop = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //const pathName = window.location.pathname.split("/")[2];
    const pathName = /[^/]*$/.exec(window.location.pathname)[0];
    const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required."),
        parent_id: Yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
        short_content: Yup.string().required("This field is required."),
        meta_title: Yup.string().required("This field is required."),
        meta_key: Yup.string().required("This field is required."),
        meta_description: Yup.string().required("This field is required."),
        categoryImage: Yup.mixed()
            .test("required", "This field is required.", (file) => {
                if (file) return true;
                return false;
            })
            .test("fileSize", "Image size can not be more than 5 MB", (file) => {

                if (file.length === 0)
                    return true;

                return file.length && file[0].size <= 5242880;
            })
            .test("fileType", "Only *.jpeg, *.jpg, *.png type of images are allowed.", (file) => {
                if (file.length === 0)
                    return true;

                return (
                    file.length &&
                    ["image/jpeg", "image/png", "image/jpg"].includes(file[0].type)
                );
            }),
    });

    const formOptions = {
        resolver: yupResolver(validationSchema),
    };

    const { register, unregister, handleSubmit, formState, trigger, setValue, getValues } = useForm(formOptions);
    const { errors } = formState;

    const [selectedImage, setSelectedImage] = useState(null);
    const { onChange, ...params } = register("categoryImage");


    const handleRouteTo = (path) => {
        navigate(path);
    };

    const onSubmitForm = async (data) => {
        console.log(data);
        dispatch(setRequestInProgress());

        const formData = new FormData();
        formData.append("id", id);
        formData.append("name", data.name);
        formData.append("short_content", data.short_content);
        formData.append("parent_id", data.parent_id == undefined ? 0 : data.parent_id);
        formData.append("meta_title", data.meta_title);
        formData.append("meta_key", data.meta_key);
        formData.append("meta_description", data.meta_description);
        formData.append("status", data.status ? 1 : 0);
        formData.append("featured", data.featured ? 1 : 0);
        if (data.categoryImage.length > 0) {
            formData.append("file", data.categoryImage[0]);
        }
        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.UPDATE_CATEGORY, formData);
            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Category updated successfully.");
                handleRouteTo("/e-commerce/category");

            } else {
                dispatch(setRequestFailure("Getting error while creating Category."));
                createNotification("error", "", "Getting error while creating Category.");
            }
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            dispatch(setRequestFailure("Getting error while creating Category."));
            createNotification("error", "", "Getting error while creating Category.");
        }
    };


    const handleFile = (e) => {

        const image = e.target.files[0];
        if (!image)
            return false;

        setCategoryImgPreview(URL.createObjectURL(e.target.files[0]));
        return e.target.files[0];
    };

    const handleChange = (event) => {
        setCategoryDetail({ ...categoryDetail, status: event.target.checked });
    };

    const handleChangeFeatured = (event) => {
        setCategoryDetail({ ...categoryDetail, featured: event.target.checked });
    };

    const handleChangeMainCategory = (e) => {
        setMainCategory(e.target.value);
    };

    const getMainSelectCategory = async () => {
        try {
            const data = await getMainCategory(id);
            if(data.data.data !== null){
                setMainCategoryDataList(data.data.data);
            }
        } catch (error) {
            checkTokenExpiry(error);
        }
    };


    async function getCategory(id) {

        try {
            dispatch(setRequestInProgress());
            const response = await getRequest(GENERAL_SETTINGS.GET_CATEGORY_BY_ID.replace('{ID}', id));
            const { status, data: { data } } = response;
            if (status === 200 && data) {
                setCategoryDetail(data);
                setCategoryImgPreview(`${ASSETS_URL.IMAGE}/${data?.img}`);
                setValue('name', data.name);
                setValue('parent_id', data.parent_id);
                setParentId(data.parent_id)
                setValue('short_content', data.short_content);
                setValue('meta_title', data.meta_title);
                setValue('meta_key', data.meta_key);
                setValue('meta_description', data.meta_description);
                setValue('status', data.status ? true : false);
                setValue('featured', data.featured ? true : false);
                dispatch(setRequestInit());
                unregister('categoryImage')
                // unregister("yourDetails")
            } else if (data === null) {
                dispatch(setRequestFailure("Category not found."));
                createNotification("error", "", "Category not found.");
                handleRouteTo('/e-commerce/category')
            }
        } catch (error) {
            dispatch(setRequestFailure("Getting error while removing profile picture. Please try  again."));
        }
    }
    useEffect(() => {
        getMainSelectCategory();
        getCategory(id);
    }, [id]);
    return (
        <>
            <Helmet>
                <title> {`Update`} </title>
            </Helmet>

            {loading && (
                <Box
                    onClick={() => setloading(false)}
                    sx={{
                        display: "flex",
                        position: "fixed",
                        height: "100%",
                        width: "75%",
                        bottom: "0",
                        backgroundColor: "white",
                        alignItems: "center",
                        justifyContent: "center",

                        zIndex: "1000",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            <Container ref={formTop}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={0}
                >
                    <Typography variant="h4" gutterBottom>
                        Category
                    </Typography>
                </Stack>
                <Breadcrumbs aria-label="breadcrumb" mb={5}>
                    <Link style={{ textDecoration: "none", color: "black" }} to="/">
                        Dashboard
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/e-commerce/category`}
                    >
                        Category
                    </Link>
                    <Typography color="text.primary">Update Category</Typography>
                </Breadcrumbs>

                <Card>
                    <CardHeader title={`Update Category`} />
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmitForm)}
                        sx={{ pt: 5, pl: 3, pr: 3, pb: 5 }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item md={12}>
                                <Grid item mb={3} xs={12} md={12}>
                                    <FormControl fullWidth>
                                        <Stack>
                                            <InputLabel id="demo-simple-select-standard-label">
                                                Parent Category
                                            </InputLabel>
                                            <Select
                                                id="parent_id"
                                                name="parent_id"
                                                error={errors?.parent_id}
                                                value={parentId}
                                                {...register("parent_id", {
                                                    onChange: handleChangeMainCategory,
                                                })}
                                                input={<OutlinedInput label="Parent Category" />}
                                                label="Parent Category"
                                            >
                                                {mainCategoryDataList.map((name) => (
                                                    <MenuItem key={name.name} value={name.id}>
                                                        {name.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Stack>
                                    </FormControl>
                                </Grid>
                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        {...register("name", { required: true })}
                                        error={errors.name}
                                        helperText={
                                            errors.name ? errors.name.message : false
                                        }
                                        disabled={loader}
                                        id="news-title"
                                        label="Name*"
                                        name="name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        {...register("short_content", { required: true })}
                                        error={errors.short_content}
                                        helperText={
                                            errors.short_content ? errors.short_content.message : false
                                        }
                                        disabled={loader}
                                        id="short_content"
                                        label="Short Content*"
                                        name="short_content"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        {...register("meta_title", { required: true })}
                                        error={errors.meta_title}
                                        helperText={
                                            errors.meta_title ? errors.meta_title.message : false
                                        }
                                        disabled={loader}
                                        id="meta_title"
                                        label="Meta Title*"
                                        name="meta_title"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        {...register("meta_key", { required: true })}
                                        error={errors.meta_key}
                                        helperText={
                                            errors.meta_key ? errors.meta_key.message : false
                                        }
                                        disabled={loader}
                                        id="meta_key"
                                        label="Meta Key*"
                                        name="meta_key"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        {...register("meta_description", { required: true })}
                                        error={errors.meta_description}
                                        helperText={
                                            errors.meta_description ? errors.meta_description.message : false
                                        }
                                        disabled={loader}
                                        id="meta_description"
                                        label="Meta Description*"
                                        name="meta_description"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item mb={3} xs={12} md={12}>
                                    <div
                                        className={
                                            errors.categoryImage
                                                ? "errorCkEditor"
                                                : "borderInput"
                                        }
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            padding: "20px",
                                            postition: "relative",
                                        }}
                                    >
                                        {categoryImgPreview &&
                                            <img
                                                src={categoryImgPreview}
                                                style={{
                                                    height: "190px",
                                                    marginBottom: "10px",
                                                    width: "190px",
                                                }}
                                                alt="something"
                                                loading="lazy"
                                            />
                                        }
                                        <span
                                            style={{
                                                color: errors.categoryImage ? "#ff5c56" : "#8d9299",
                                                fontSize: "14px",
                                                marginBottom: "20px",
                                                textAlign: 'center'
                                            }}
                                        >
                                            Only *.jpeg, *.jpg, *.png type of images are allowed, Image size can not be more than 5 MB ).</span>

                                        <Button
                                            disabled={loader}
                                            variant="contained" component="label">
                                            Upload image
                                            <input
                                                {...params}
                                                type="file"
                                                id="uploadFileInput"
                                                accept="image/*"
                                                name="categoryImage"
                                                onChange={(event) => {
                                                    setSelectedImage(event.target.files[0]);
                                                    onChange(event);
                                                    trigger('categoryImage');
                                                    handleFile(event)
                                                }}
                                                style={{ display: 'none' }}
                                            />
                                        </Button>
                                    </div>
                                    {errors.categoryImage &&
                                        <p className="errorForm">{errors.categoryImage.message}</p>
                                    }
                                </Grid>
                                <Grid item mb={3} xs={12} md={12}>
                                    <FormGroup>
                                        <Stack spacing={3} direction="row">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        {...register("featured")}
                                                        checked={categoryDetail.featured}
                                                        name="featured"
                                                        onChange={handleChangeFeatured}
                                                        disabled={loader}
                                                        color="success"
                                                    />
                                                }
                                                label="Featured"
                                            />
                                        </Stack>
                                    </FormGroup>
                                </Grid>
                                <Grid item mb={3} xs={12} md={12}>
                                    <FormGroup>
                                        <Stack spacing={3} direction="row">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        {...register("status")}
                                                        checked={categoryDetail.status}
                                                        name="status"
                                                        onChange={handleChange}
                                                        disabled={loader}
                                                        color="success"
                                                    />
                                                }
                                                label="Status"
                                            />
                                        </Stack>
                                    </FormGroup>
                                </Grid>

                                <Grid
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    my={2}
                                >
                                    <LoadingButton
                                        loading={loader ? true : false}
                                        size="medium"
                                        type="submit"
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        {loader ? 'PLEASE WAIT...' : 'UPDATE'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Container>
        </>
    );
}

export default EditCategory;