import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import useResponsive from "../hooks/useResponsive";
import { useSelector } from "react-redux";
import AlertDialog from "../common/AlertDialog";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import "./style.css";
import { checkTokenExpiry } from "../utils/helpers/functions";

// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { createNotification } from "../common/createNotification";
// components
import Iconify from "../components/iconify";
// sections
import UserListHead from "../app/user/UserListHead";
import { getSubscriberList, removeSubscriber } from "src/api/subscriberList";
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "email", label: "Email", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

export default function Subscribers() {
  const pathName = window.location.pathname.split("/")[2];
  const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);

  const { NAV_WIDTH } = useSelector((state) => state.news);
  const isDesktop = useResponsive("up", "lg");

  const [order, setOrder] = useState("asc");
  const [error, seterror] = useState("");
  const [selected, setSelected] = useState({});
  const [counts, setCounts] = useState(0);
  const [orderBy, setOrderBy] = useState("email");
  const [isAscending, setIsAscending] = useState(false);
  const [USERLIST, setUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [newsId, setnewsId] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const res = await getSubscriberList(page + 1, rowsPerPage);
      setUserList(res.data.data.rows);
      setCounts(res.data.data.count);
      setLoading(false);
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
      seterror("Error fetching data ");
      createNotification("error", "Error", "Error fetching data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    USERLIST.sort((a, b) => {
      const fa = a.email.toLowerCase();
      const fb = b.email.toLowerCase();
      if (isAscending === false) {
        if (fa < fb) {
          setIsAscending(true);
          return -1;
        }

        if (fa > fb) {
          setIsAscending(true);
          return 1;
        }
      } else if (isAscending === true) {
        if (fa > fb) {
          setIsAscending(false);
          return -1;
        }

        if (fa > fb) {
          setIsAscending(false);
          return 1;
        }
      }

      return 0;
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDeleteById = async (id) => {
    await removeSubscriber(id);
    await fetchUsers();
    createNotification("success", "", "Deleted Successfully");
  };


  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    const NewUserList = [];
    USERLIST.forEach(function (obj, key) {
      NewUserList.push({ 'Email': obj.email });
    });

    const worksheet = XLSX.utils.json_to_sheet(NewUserList);
    const wscols = TABLE_HEAD.map((column) => ({ wpx: 120 }));
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contacts');
    const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, 'subscribers.xlsx');
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };




  return (
    <>
      <Helmet>
        <title>{path}</title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Subscribers
          </Typography>
          <Button onClick={handleDownload}
            variant="contained"
            startIcon={<Iconify icon="bxs:file" />}
          >
            Export Subscribers
          </Button>
        </Stack>

        {loading === true ? (
          <div className="loading_main">
            <div
              className="loading_inner"
              style={{
                width: isDesktop ? `calc(100% - ${NAV_WIDTH - 600}px)` : "100%",
              }}
            >
              <CircularProgress />
            </div>
          </div>
        ) : (
          <>
            <Card>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />

                  {USERLIST.map((item) => (
                    <TableBody key={item.id} className="table-spacer">
                      <TableRow hover key={item.id} tabIndex={-1} spacing={3}>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {item.email}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="center">
                          <div
                            style={{
                              display: "flex",
                              color: "red",
                              cursor: "pointer",
                            }}
                          >
                            <Iconify
                              onClick={() => {
                                setnewsId(item.id);
                                setModalShow(true);
                              }}
                              icon={"eva:trash-2-outline"}
                              sx={{ mr: 2, color: "red" }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                  {USERLIST.length === 0 ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              {error}
                            </Typography>

                            <Typography variant="body2">
                              No data found
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
              {USERLIST.length > 0 &&
                <TablePagination
                  //rowsPerPageOptions={[]}
                  rowsPerPageOptions={USERLIST.length > 0 ? [5, 10, 25, 50, 100, 500, 1000] : false}
                  component="div"
                  count={counts}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              }
            </Card>
          </>
        )}
      </Container>
      <AlertDialog
        open={modalShow}
        setOpen={setModalShow}
        deletefunction={() => handleDeleteById(newsId)}
      />
    </>
  );
}
