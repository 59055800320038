import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setRequestInProgress, setRequestFailure, setRequestInit } from "../../../redux/Slices/RequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { Card, Table, Popover, MenuItem, TableBody, TableContainer, Typography, TablePagination } from "@mui/material";
import AlertDialog from "../../../common/AlertDialog";
import Paper from "@mui/material/Paper";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import { NewsListHead } from "../../../sections/@dashboard/news";
import useSearchDebounce from 'src/utils/hooks/textDebounce';
import { checkTokenExpiry } from "../../../utils/helpers/functions";
import { createNotification } from "src/common/createNotification";

import { deleteRequest, getRequest, postRequest } from "src/app/httpClient/axiosClient";
import { GENERAL_SETTINGS } from 'src/app/config/endpoints';


import ProductItem from "./productitem";

function ProductListing(props) {
    const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);
    const { setloading } = props;
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const order = "asc";
    const orderBy = "name";

    const [pageNo, setPageNo] = useState(0);
    const [progressLoading, setProgressLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchText, setSearchText] = useState('');
    const [searchTextDebounce, setSearchTextDebounce] = useSearchDebounce();
    const [productList, setProductList] = useState([]);
    const [totalNewsCount, setTotalNewsCount] = useState(0);
    const [open, setOpen] = useState(null);
    const [hoveredNewsId, setHoveredNewsId] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [srNo, setSrNo] = useState(1);

    const handleOpenMenu = (event, id) => {
        setOpen(event.currentTarget);
        setHoveredNewsId(id);
    };

    const handleCloseMenu = () => {
        setOpen(null);
        // setHoveredNewsId(null);
    };

    const handleRouteTo = (path) => {
        navigate(`/e-commerce/product/${path}`);
    };

    const handleClickOpen = () => {
        setModalShow(true);
    };

    const handleChangePage = (event, newPage) => {
        setPageNo(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageNo(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handlePublished = async (STATUS, ID, COLUMN) => {
        const _STATUS = STATUS ? 1 : 0
        const _ENDPONIT = `${GENERAL_SETTINGS.UPDATE_PRODUCT_STATUS}?id=${ID}&status=${_STATUS}&column=${COLUMN}`;
        try {
            setloading(true)
            const { status, data: { data } } = await getRequest(_ENDPONIT);
            console.log({ status, data });
            setloading(false);
            createNotification("success", "Update", "Status updated successfully.");
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            createNotification("error", "Update", "Getting error while updating status. Please try  again.");
        }
    };

    const deleteProductById = async () => {
        try {
            setOpen(null);
            setloading(true);
            const { status, data: { data } } = await deleteRequest(GENERAL_SETTINGS.DELETE_PRODUCT.replace('{ID}', hoveredNewsId));
            //console.log({ status, data });
            setloading(false);
            createNotification("success", "Success", `Product was successfully deleted.`);
            getProductList();
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            createNotification("error", "Update", "Getting error while deleting product value. Please try  again.");
        }
    };

    async function getProductList() {
        const _pageNo = pageNo + 1;
        const _ENDPONIT = `${GENERAL_SETTINGS.GET_PRODUCTS}?page=${_pageNo}&limit=${rowsPerPage}`;
        try {
            dispatch(setRequestInProgress());
            const response = await getRequest(_ENDPONIT);
            const { status, data: { data,metaData } } = response;
            if (status === 200) {
                console.log(data);
                setProductList(data);
                setTotalNewsCount(metaData.count)
                //console.log("response ==> ", metaData.count);
                dispatch(setRequestInit());
            } else { }
        } catch (error) {
            console.log(error);
            dispatch(setRequestFailure("Getting error while removing profile picture. Please try again."));
        }
    }

    useEffect(() => {
        getProductList();
    }, [pageNo, rowsPerPage, searchTextDebounce]);

    return <>
        
        <Card>
            <Scrollbar>
                <TableContainer
                    component={Paper}
                    sx={{ minWidth: 800, borderRadius: "0px" }}
                >
                    <Table>
                        <NewsListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={10}
                            numSelected={1}
                        />
                        <TableBody>
                            <ProductItem
                                loading={loader}
                                errorMessage={errorMessage}
                                successMessage={successMessage}
                                productList={productList}
                                srNo={srNo}
                                handleRouteTo={handleRouteTo}
                                handlePublished={handlePublished}
                                handleOpenMenu={handleOpenMenu}
                                searchTextDebounce={searchTextDebounce}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
                {!errorMessage && productList.length > 0 &&
                    <TablePagination
                        rowsPerPageOptions={ productList.length > 0 ? [5, 10, 25, 50, 100]:false}
                        component="div"
                        count={totalNewsCount}
                        rowsPerPage={rowsPerPage}
                        page={pageNo}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        progressLoading={progressLoading}
                    />
                }
            </Scrollbar>
        </Card>
        <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: {
                    p: 1,
                    width: 140,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                },
            }}
        >
            <MenuItem onClick={() => hoveredNewsId ? handleRouteTo(`${hoveredNewsId}`) : false}>
                <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />Edit
            </MenuItem>
            <MenuItem
                sx={{ color: "error.main" }}
                onClick={() => {
                    handleClickOpen();
                    handleCloseMenu();
                }}
            >
                <Iconify
                    icon={"eva:trash-2-outline"}
                    sx={{ mr: 2 }}
                />
                Delete
            </MenuItem>
        </Popover>

        <AlertDialog
            open={modalShow}
            setOpen={setModalShow}
            deletefunction={deleteProductById}
            moduleType={'product'}
        />
    </>
}

export default ProductListing;
const TABLE_HEAD = [
    { id: "Image", className: '', label: "Image", align: '' },
    { id: "SKU", className: '', label: "SKU", align: '' },
    { id: "Category", className: '', label: "Category", align: '' },
    { id: "Name", className: '', label: "Name", align: '' },
    { id: "Description", className: '', label: "Description", align: '' },
    { id: "Status", className: '', label: "Status", align: '' },
    { id: "Featured", className: '', label: "Featured", align: '' },
    { id: "options", className: '', label: "Options", align: 'right' },
];