
import { image } from "src/constants/api";
import { Stack, TableRow, TableCell, Paper, CircularProgress, Typography, IconButton, Button } from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import { useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { SwitchComponent } from "../../../components/Switch";
import Iconify from "../../../components/iconify";
// import { ASSETS_URL } from "src/app/config/endpoints";
import { Link } from "react-router-dom";
import OrderListModal from "./OrderListModal"



const _key = (index) => {
    return `key-${index}-${Math.floor(Math.random() * 99999) + 1}`;
}

var options = { year: "numeric", month: "long", day: "numeric" };

function OrderItem(props) {
    const { loading, handlePublished, handleFeatured, handleOpenMenu, errorMessage, orderList, searchTextDebounce, isDesktop, navWidth, srNo } = props;

    // confirmation box  start
    const [open, setOpen] = useState(false);
    const [childRows, setChildRows] = useState([]);
    const [invoiceNo, setInvoiceNo] = useState('');


    const handleOpen = (ordersData, invoice_no) => {
        console.log(invoice_no)
        setOpen(true)
        setChildRows(ordersData)
        setInvoiceNo(invoice_no)

        console.log("open", ordersData)

    }




    if (loading) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <CircularProgress />
                <Typography variant="subtitle2" className="user-name" noWrap>
                    Please wait...
                </Typography>
            </TableCell>
        </TableRow>
    }

    if (errorMessage) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <Typography
                    sx={{ color: 'red' }}
                    variant="subtitle2" className="user-name" noWrap>
                    {errorMessage}
                </Typography>
            </TableCell>
        </TableRow>
    }

    if (orderList.length === 0) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <Paper sx={{ textAlign: "center", }}>
                    <Typography variant="h6" paragraph>
                        Data Not found
                    </Typography>
                </Paper>
            </TableCell>
        </TableRow>
    }

    return <>

        <OrderListModal open={open} setOpen={setOpen} childRows={childRows} invoiceNo={invoiceNo} />
        {
            orderList.map((item, index) => {


                let orderAddressObject = JSON.parse(item.orders[0].order_address);


                return (

                    <TableRow hover key={index} tabIndex={-1} role="checkbox">
                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>

                                <Typography className="category word-wrap">
                                    {index + 1}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="category word-wrap">
                                    {item.orders[0].user.name}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="category word-wrap">
                                    {item.invoice_no}
                                </Typography>
                            </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="category word-wrap">
                                    {orderAddressObject.shipping_first_name} {orderAddressObject.shipping_last_name}, <br /> {orderAddressObject.shipping_address_line_1} {orderAddressObject.shipping_address_line_2} ,<br /> {orderAddressObject.shipping_city}, {orderAddressObject.shipping_country} ,{orderAddressObject.shipping_zip},<br />
                                    {orderAddressObject.shipping_phone}
                                </Typography>
                            </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="category word-wrap">
                                    $ {item.orders[0].total_price}
                                </Typography>
                            </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="category word-wrap">
                                    $ {item.orders[0].ship_amt}
                                </Typography>
                            </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="category word-wrap">
                                    $ {item.orders[0].order_total}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="category word-wrap">
                                    {item.payment_status === 0 ? <span style={{ backgroundColor: '#dd4b39', padding: "4px", color: "#fff", width: 'max-content', fontSize: '8px' }}>failed</span> : <span style={{ backgroundColor: '#00a65a ', padding: "4px", color: "#fff", width: 'max-content', fontSize: '8px' }}>success</span>}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="category word-wrap" style={{ color: 'white', fontSize: '8px', width: 'max-content' }} >
                                    {item.status === 0 ? <span style={{ backgroundColor: '#ff851b', padding: "4px" }}>Pending</span> :
                                        item.status === -1 ? <span style={{ backgroundColor: '#dd4b39', padding: "4px" }} > Cancelled</span> :
                                            item.status === 1 ? <span style={{ backgroundColor: '#d9edf7', padding: "4px" }}> Processing</span> :
                                                item.status === 2 ? <span style={{ backgroundColor: '#00a65a ', padding: "4px" }}> Dispatched</span> :
                                                    <span style={{ backgroundColor: '#00a65a ', padding: "4px" }}>Completed</span>}

                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="category word-wrap">
                                    <Link to={`/e-commerce/orders/print/${item.invoice_no}`}>   <PrintIcon />   </Link>
                                    <Link to={`/e-commerce/orders/${item.invoice_no}`}>  <VisibilityIcon />   </Link>


                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="category word-wrap">
                                    <Button onClick={() => { handleOpen(item.orders, item.invoice_no) }}> <KeyboardArrowDownIcon /></Button>
                                </Typography>
                            </Stack>
                        </TableCell>


                    </TableRow>
                )
            })
        }

    </>
}

export default OrderItem;




