import React from 'react';
import { Grid, Select, MenuItem, TextareaAutosize, FormControl, InputLabel, Button, Typography } from '@mui/material';

const FormComponent = () => {
  const handleUpdateClick = () => {
    // Handle update logic here
    console.log('Update button clicked');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="select-label-1">Payment Status</InputLabel>
          <Select
            labelId="select-label-1"
            id="select-1"
            defaultValue=""
            label="Payment Status"
            fullWidth
          >
            <MenuItem value={1}>Option 1</MenuItem>
            <MenuItem value={2}>Option 2</MenuItem>
            <MenuItem value={3}>Option 3</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="select-label-2">Order Action</InputLabel>
          <Select
            labelId="select-label-2"
            id="select-2"
            defaultValue=""
            label="Order Action"
            fullWidth
          >
            <MenuItem value={1}>Option A</MenuItem>
            <MenuItem value={2}>Option B</MenuItem>
            <MenuItem value={3}>Option C</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Typography variant="subtitle2" gutterBottom>
            Text Area Label
          </Typography>
          <TextareaAutosize
            aria-label="text area"
            placeholder="Enter your text here"
            style={{ width: '100%', minHeight: 100, padding: 10 }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end"  sx={{paddingY:2}}>
        <Button variant="contained" color="primary" onClick={handleUpdateClick}>
          Update
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormComponent;
