import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import { checkTokenExpiry } from "../../utils/helpers/functions";
import PodcastListing from "./podcastlists";
// @mui
import {
  Stack,
  Button,
  Container,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";

import Iconify from "../../components/iconify";
import { useSelector } from "react-redux";

function Podcast() {
  const [loading, setloading] = useState(false);

  //CONSTANTS------------------------------------------------------------
  let navigate = useNavigate();
  const isDesktop = useResponsive("up", "lg");
  const pathName = window.location.pathname.split("/")[2];
  const { NAV_WIDTH } = useSelector((state) => state.news);
  const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);
  
  const handleRouteTo = (path) => {
    navigate(path);
  };

  return (
    <>
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="assets/icons/sas.png"
        />
        <title> {path} </title>
      </Helmet>

      <Container>
        {loading && (
          <Box
            onClick={() => setloading(false)}
            sx={{
              display: "flex",
              position: "fixed",
              height: "100%",
              width: isDesktop ? `calc(100% - ${NAV_WIDTH + 1}px)` : "100%",
              bottom: "0",
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",

              zIndex: "1000",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Podcast
          </Typography>
          <Button
            onClick={() => handleRouteTo("create-podcast")}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Create Podcast
          </Button>
        </Stack>

        <PodcastListing
          setloading={setloading}
        />
      </Container>
    </>
  );
}

export default Podcast;