export function formatOrderDate(createdDate) {
  const _date = new Date(createdDate);
  return _date.toLocaleDateString("en-US", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
}

export function commaSeparated(_param) {
  return _param.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function validateEmail(email) {
  var regex = /\S+@\S+\.\S+/;
  return regex.test(email);
}

export function checkTokenExpiry(error) {
  if (error === "Session Expired!") {
    localStorage.removeItem("user");
    window.location.reload();
  }
}


export function get_thumbnail(url, quality="medium") {
  if (url) {
    var video_id, thumbnail, result;
    if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
      video_id = result.pop();
    } else if ((result = url.match(/youtu.be\/(.{11})/))) {
      video_id = result.pop();
    }
    if (video_id) {
      if (typeof quality == "undefined") {
        quality = "high";
      }

      var quality_key = "maxresdefault"; // Max quality
      // eslint-disable-next-line eqeqeq

      if (quality == "low") {
        quality_key = "sddefault";
        // eslint-disable-next-line eqeqeq
      } else if (quality == "medium") {
        quality_key = "mqdefault";
        // eslint-disable-next-line eqeqeq
      } else if (quality == "high") {
        quality_key = "hqdefault";
      }
      thumbnail =
        "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
      return thumbnail;
    }
  }
  return false;
}

export function getThumbnailLink(url) {
  const match = url.match(/(?:watch\?v=)(?:[a-zA-Z0-9]{0,})/gi)[0].split('=');
  console.log("match: ", match);
  const videoId = match[match.length - 1]
  const thumbnailLink = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
  return thumbnailLink;
}

export function matchYoutubeUrl(url) {
  var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  var matches = url?.match(p);
  if(matches) return true;
  return false;
}


export const wordsCapping = (_STRING, _LENGTH = 100) => {
  var TEXT = document.createElement("textarea");
  TEXT.innerHTML = _STRING;
  return `${TEXT.value.slice(0, _LENGTH)}${TEXT.value.length > _LENGTH ? `...` : ``}`;
}



export const FilterDate = (data) => {
  return data.split('T')[0];
};

export const SeoFriendlySlug = ( __slug ) => {
  let slug;
  slug = __slug.toLowerCase();
  // slug =  allReplace(slug, {' ':'-', '.':'-', '--':'-'});
  return allReplace(slug, {' ':'-', '/':'', '--':'-'});
};

export function allReplace(string, object) {
  for (const index in object) {
    string = string.replace(new RegExp(index, 'g'), object[index]);
  }
  return string;
};







