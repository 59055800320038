import axios from "axios";
import { port, user } from "../constants/api";
import { checkTokenExpiry } from "../utils/helpers/functions";

export const logIn = async (data, setError, seterror, setloading) => {
  try {
    return await axios.post(`${port}/login`, data);
  } catch (error) {
    console.log(error)
    checkTokenExpiry(error.response.data.msg);
    setloading(false);
    let response = error?.response?.data?.msg;
    const msgError = response?.charAt(0)?.toUpperCase() + response?.slice(1);
    if (!msgError) {
      seterror("Error logging in , please try again");
    } else if (response.toLowerCase()?.includes("email")) {
      setError("email", { type: "custom", message: msgError });
    } else if (msgError?.includes("Password")) {
      setError("password", {
        type: "custom",
        message: msgError,
      });
    } else if (msgError?.includes("password")) {
      const msgError = response?.charAt(1).toUpperCase() + response?.slice(2);
      setError("password", {
        type: "custom",
        message: msgError,
      });
    }
  }
};

export const getUser = async (id) => {
  return axios.get(`${port}/getUserProfileById?id=${id}`, {
    headers: {
      authorization: user.token,
    },
  });
};
export const updateUser = async (data) => {
  return axios.post(`${port}/updateProfileByUserId`, data, {
    headers: {
      authorization: user.token,
    },
  });
};
export const deleteProfileImage = async (id) => {
  return axios.post(
    `${port}/deleteProfileByUserId/${id}`,
    { userId: id },
    {
      headers: {
        authorization: user.token,
      },
    }
  );
};

export const changePassword = async (data) => {
  return axios.post(`${port}/resetPassword`, data, {
    headers: {
      authorization: user.token,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getDashboard = async () => {
  return axios.get(`${port}/fetchDashboardList`, {
    headers: {
      authorization: user.token,
    },
  });
};

export const forgetPassword = async (data) => {
  return axios.post(`${port}/forgetPassword`, data, {});
};
export const setPassword = async (data) => {
  return axios.post(`${port}/setPassword`, data, {});
};
